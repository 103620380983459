import { Component, OnInit,ViewChild } from '@angular/core';
import { BoxChatComponent } from "./box-chat/box-chat.component";
import { BoxInputComponent } from "./box-input/box-input.component";
import { Socket } from 'ngx-socket-io';
import { GeneralService } from '../../../services/general.service';
import { SocketIoService } from '../../../services/socket-io.service'
import { BehaviorSubject } from 'rxjs';


/*import { SocketFactory } from 'ngx-socket-io/src/socket-io.module';*/
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  NotifacationNew$ = new BehaviorSubject<string[]>([]);

  @ViewChild(BoxChatComponent) boxChat;
  //BoxImputComponent
  @ViewChild(BoxInputComponent) boxInpuy;
  user:any = JSON.parse( localStorage.getItem("userData") );
  chatTexty;
  chatText:string;
  chatting=false;
  SelectMeet;
  NotifacationNew = [];
  names;
  dataMessage;
  band;
  inputChat=false;
  files:any;
  boolInputSend:boolean = false;
   meetting_id =  localStorage.getItem("meetting_id_current");
  boolInputEdit:boolean = false;
  
  constructor( private socket: SocketIoService, private GeneralService: GeneralService) { }

  ngOnInit(): void {
    this.user=JSON.parse(localStorage.getItem('userData'));
    localStorage.setItem("boolInputSend","0");
    localStorage.setItem("boolInputEdit","0");

    this.socket.getNewMessage().subscribe((message: string) => {
      this.parentMessage.push(message);
      // console.log('push mensaje: ' + message)
    })

    // console.log(this.user);
    this.socket.listenToServerEvents('sendMessagesPrivate').subscribe((data: any) => {
      this.NotifacationNew.push(data.meeting_id);
      console.log("test [] notfication")
      console.log(this.NotifacationNew)
      this.NotifacationNew$.next(this.NotifacationNew)
    if(this.SelectMeet == data.meeting_id){
      console.log("se pusheo")
      this.parentMessage.push(data);
    }

    });
    
  }
  message:string="";
  parentMessage;
  receiveMessage($event) {
    if (this.SelectMeet !== $event) {
      this.parentMessage = [];
    }
this.SelectMeet = $event;

    this.socket.getMessages(this.SelectMeet);

    const menssagesLast = this.socket.listenForLastChats().subscribe(
      (data: any[]) => {
        this.parentMessage = data;
        if (data && data.length > 0) {
          const lastChat = data[data.length - 1]; 
          if( this.user.id !== lastChat.user_id){
            console.log("te clavaron el visto pibe")
            this.socket.seenMessage(lastChat._id)
            console.log(lastChat)
          }

        }
      },
      error => {
        console.error('Error en la solicitud HTTP:', error);
      }
    );

    this.parentMessage
    this.inputChat=true;
  }
  
  addInput(e){
    let userData = JSON.parse( localStorage.getItem("userData") );
    console.log(userData)
    
    this.dataMessage = {
      meeting_id : localStorage.getItem("meetting_id_current")  ,
      message: e,
      user_id: this.user.id,
      image: userData.avatar,
      typeFile: "data.typeFile",
      fullname: userData.name,
    }
    console.log(this.dataMessage);
    this.socket.SenderMessage(this.dataMessage);
    this.message = "";
    // console.log("asdasd")
    /*
    if(this.chatText!=""){
    this.chatTexty=this.chatText;
    this.chatText=""
    }
    */

  }
  functionName($event){
    console.log($event)
    this.names=$event
    this.band=this.boxChat.band;
  }
  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'twitter';
  toggleEmojiPicker() {
    console.log(this.showEmojiPicker);
        this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event) {
    console.log(this.message);
    //const { message } = this;
    //console.log(message);
    console.log(`${event.emoji.native}`)
    const text = `${this.message}${event.emoji.native}`;

    this.message = text;
    // this.showEmojiPicker = false;
  }

  onFocus() {
    console.log('focus');
    this.showEmojiPicker = false;
    //this.boolInputSend = true;
    localStorage.setItem("boolInputSend","1");

  }
  onBlur() {
    console.log('onblur')
  }

  onFileChange(event){
    if (event.target.files.length > 0) {
      this.files = event.target.files;
      console.log(this.files);
      let data = JSON.parse( localStorage.getItem("userData") );
      let meetting_id =  localStorage.getItem("meetting_id_current")
      
      this.GeneralService.sendFileChat(this.files, data.id , meetting_id ).then(data => {
        console.log("send file = ", data )
        // this.socket.emit("show_chat",{ meetting_id : localStorage.getItem("meetting_id_current") , user_id :  JSON.parse( localStorage.getItem("userData") ).id });
      });
    }
  }


}
