<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between none"
  fxLayoutGap="36px"
  style="background: #e2e2e2;"
  class="template-padding"
>
  <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div
      fxLayout="row"
      fxLayout.lt-md="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap.gt-sm="20px"
      fxLayoutGap.lt-md="10px"
    >
      <input
        class="input-searching"
        [(ngModel)]="keyword"
        fxFlex
        (keyup.enter)="searches()"
      />
      <button mat-raised-button fxFlex class="registrate" (click)="searches()">
        Buscar
      </button>
      <button mat-raised-button fxFlex class="registrate" (click)="Show()">
        <mat-icon>filter_list</mat-icon> Filtar
      </button>
    </div>
    <div
      *ngIf="show"
      fxLayout="row"
      fxLayout.lt-md="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="2%"
      class="styled-select"
    >
      <select
        *ngIf="this.searching == 'lugares'"
        [(ngModel)]="keywordCategory"
        fxFlex
        class="select-searching"
        (change)="lastId=null;refreshfilter()"
      >
        <option value="" disabled>Seleccione Categoria</option>
        <option
          *ngFor="let placeCategory of placecategories"
          value="{{ placeCategory.name }}"
        >
          {{ placeCategory.name }}
        </option>
      </select>
      <select
        *ngIf="this.searching != 'lugares'"
        [(ngModel)]="keywordCategory"
        fxFlex
        class="select-searching"
        (change)="lastId=null;refreshfilter()"
      >
        <option value="" disabled>Seleccione Categoria</option>
        <option
          *ngFor="let course of courseCategories"
          value="{{ course.name }}"
        >
          {{ course.name }}
        </option>
      </select>
      <!-- courseCategories -->
      <select
        *ngIf="
          this.searching != 'lugares' &&
          this.searching != 'eventos' &&
          this.searching != 'events'
        "
        [(ngModel)]="keywordLevel"
        fxFlex
        class="select-searching"
        (change)="lastId=null;refreshfilter()"
      >
        <option selected value="">Seleccione Nivel</option>
        <option *ngFor="let leve of level" value="{{ leve.name }}">
          {{ leve.name }}
        </option>
      </select>
      <select
        *ngIf="allplaces"
        [(ngModel)]="keyword"
        fxFlex
        class="select-searching"
        (change)="lastId=null;refreshfilter()"
      >
        <option selected value="">Lugar</option>
        <option *ngFor="let place of alldata" value="{{ place.name }}">
          {{ place.name }}
        </option>
      </select>
      <select
        *ngIf="status > 0"
        [(ngModel)]="country_id"
        fxFlex
        class="select-searching"
        (change)="lastId=null;refreshfilter()"
      >
        <option value="" disabled>Seleccione Pais</option>
        <option
          *ngFor="let country of countries"
          value="{{ country.name }}"
        >
          {{ country.name }}
        </option>
      </select>
      <select
        *ngIf="status > 1"
        [(ngModel)]="state_id"
        fxFlex
        class="select-searching"
        (change)="lastId=null;refreshfilter()"
      >
        <option value="" disabled>Seleccione Estado</option>
        <option
          *ngFor="let state of states"
          value="{{ state.id }}-{{ state.state_name }}"
        >
          {{ state.state_name }}
        </option>
      </select>
      <select
        [(ngModel)]="keywordCity"
        *ngIf="status > 2"
        fxFlex
        class="select-searching"
        (change)="lastId=null;refreshfilter()"
      >
        <option value="" disabled>Seleccione Ciudad</option>
        <option *ngFor="let city of cities" value="{{ city.city }}">
          {{ city.city }}
        </option>
      </select>
    </div>
  </div>
</div>

<div *ngIf="!isMobile" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" class="template-padding">
  <div fxFlex="100%" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between stretch">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutGap.gt-sm="20px" class="search-container">
      <p class="search-return">Los temas mas buscados:</p>
      <div class="topic-container" >
        <button  *ngFor="let topic of popularTopics" class="topic" (click)="searchTopic(topic.text)">
          <p>{{ topic.text }}</p>
        </button>
      </div>
    </div>
  </div>
</div>




<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between none"
  fxLayoutGap="36px"
  class="template-padding"
>
  <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap.gt-sm="20px"
    >
     
      <p
        class="search-return"
      >
        Mostrando {{ alldata?.length }} resultados
      </p>
    </div>

                <app-item-search  
                 fxLayout="row"
                fxLayout.lt-md="column"
                
                fxLayoutAlign="space-between stretch"
                fxLayoutGap.gt-sm="20px"
                fxLayoutGap.lt-md="60px"
                fxLayoutGap.lt-lg="20px"
                style="
                  margin: 40px 0 0;
                  padding-bottom: 40px;
                  border-bottom: 1px solid #000000;
                "
                *ngFor="let data of alldata" [data]="data" [searching]="searching" [isTablet]="isTablet" [isMobile]="isMobile" ></app-item-search>

       
  </div>
</div>

<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between none"
  fxLayoutGap="36px"
  class="template-padding"
>
  <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div
      fxLayout="row"
      *ngIf="loading"
      fxLayout.lt-md="column"
      fxLayoutAlign="center center stretch"
      fxLayoutGap.gt-sm="20px"
    >
      <mat-spinner></mat-spinner>
    </div>
    <div
      fxLayout="row"
      *ngIf="nomore"
      fxLayout.lt-md="column"
      fxLayoutAlign="center center stretch"
      fxLayoutGap.gt-sm="20px"
    >
      <p>No hay mas {{ screen }}</p>
    </div>
    <div
      fxLayout="row"
      *ngIf="!loading && !nomore"
      fxLayout.lt-md="column"
      fxLayoutAlign="center center stretch"
      fxLayoutGap.gt-sm="20px"
    >
      <button
        mat-raised-button
        fxFlex
        class="show-course-load"
        (click)="onWindowScroll()"
      >
        cargar {{ screen }}
      </button>
    </div>
  </div>
</div>
