import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from "../../../services/general.service";
import { NotificationsService } from "../../../services/notifications.service";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SocketIoService } from '../../../services/socket-io.service';

interface PrivateChat {
  messages: any[];
  newNotifications: number
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  screen: string | null;
  messages: any[];
  notifications = [];
  flag: boolean = false;
  total: any;
  MessagesNotifications;
  xx: any;
  isMobile: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private _route: Router,
    private NotificationsService: NotificationsService, 
    private GeneralService: GeneralService, 
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private socket: SocketIoService
  ) {
    let userData = localStorage.getItem("userData");
    if (userData !== "" && userData != null) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }
  OpenChat(id):void{
    localStorage.setItem('SelectMeeting_id', id);
    this._route.navigate(['/dashboard/chat']);
  }
  NotificationMessages():void {
    const idUser = JSON.parse(localStorage.getItem("userData")).id;
  
    this.socket.getMeeting(idUser);
    let hasReceivedData = false;
  
 this.socket.listenNotifications().subscribe((privateChats: PrivateChat) => {
  this.messages = privateChats.messages;
  this.MessagesNotifications = privateChats.newNotifications;

    });
  }
  ngOnInit(): void {
    if(this.flag === true){
      this.NotificationMessages()
      this.socket.listenToServerEvents('sendMessagesPrivate').subscribe((data: any) => {
        this.NotificationMessages()
      });
    }
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => this.isMobile = result.matches);

    let data = this.route.snapshot.paramMap.get("screen");
    this.screen = this._route.url.toString() !== '/' ? (data == null ? '1' : data) : null;

    let x = localStorage.getItem('access_token');
    this.screen = this._route.url === "/contact" ? "contact" : this.screen;
    if (x != null && x !== "") {
      let datas = JSON.parse(localStorage.getItem('userData'));
      let dataa = {
        user_id: datas.id,
        limit: 4
      };
      let aux = [];
      let text;
      let text2;

      this.NotificationsService.post_getActiveNotificationsByUserID(dataa).subscribe(resp => {
        console.log(resp);
        if (resp.message_return.ERROR == false) {
          this.total = resp.notifications.length;
          this.notifications = resp.notifications;

          this.translate.get('notifications.time').subscribe((text: string) => {
            moment.locale(text);
          });
          this.notifications = this.notifications.map((re) => {
            let time = moment(re.date).fromNow(true);
            return {
              image: re.notification_type,
              title: re.title,
              timer: `Hace ${time}`,
              status: re.Status,
              url: re.url,
              id: re.id
            }
          });
        }
      }, error => {
        console.log(error);
      });
    }
  }

  signOut() {
    localStorage.setItem('userData', "");
    localStorage.setItem('menu_user', "");
    localStorage.setItem('dashCards', "");
    localStorage.setItem('access_token', "");
    let userData = localStorage.getItem("userData");
    if (userData !== "" && userData != null) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }

  openNotificacion(index) {
    const selectNot = this.notifications[index];
    const aux = Object.assign([], this.notifications); 
    this.NotificationsService.put_notifications_status({ notification_id: selectNot.id }).subscribe(resp => {
      this.notifications = this.notifications.filter((el) => {
        return el.id !== selectNot.id;
      });
      this.total = (this.total > 0) ? (this.total - 1) : 0;
      window.location.href = aux[index].url;
    }, error => {
      console.log(error);
    });
  }
}
