import { Component, OnInit,Inject,SimpleChanges, Input, ViewChild, ElementRef  } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from "./dialog/dialog.component";
import { SocketIoService } from '../../../../services/socket-io.service';

declare var $: any;
import * as moment from 'moment';
export interface DialogData {
  animal: string;
  name: string;
}

export interface Message{
  id:number,
  name:string,
  image:string,
  message:string,
  message_id:number,
  hidden:boolean
}
@Component({
  selector: 'app-box-chat',
  templateUrl: './box-chat.component.html',
  styleUrls: ['./box-chat.component.scss']
})
export class BoxChatComponent implements OnInit {
  @ViewChild('contentBox') contentBox: ElementRef;
  animal: string;
  container: any;
  contentMsg: string;
  name: string;
  meeting_id;
  content;
  clear
  test:Message[]
  @Input() names=""
  username

  @Input() childMessage
  @Input() user; 
  @Input() chatText
  @Input() chatTexty
  datas = []
  tempChat ;
  text=[];
  band=false;
  userData = JSON.parse( localStorage.getItem("userData") )
  moment = moment;

  constructor(
    public dialog: MatDialog, private socket: SocketIoService ,
    private elementRef: ElementRef
  ) { }
   
    status=false;
    SearchChat(search: string): void {
    console.log("buscando mensaje")
      if (search) {
        console.log("paso el if")
        const searchLower = search.toLowerCase();
        console.log(searchLower)
        this.childMessage = this.tempChat.filter(objeto => objeto.message.toLowerCase().includes(searchLower));

      } else {

        this.childMessage =  this.tempChat 
      }
  
    }
    Search(e){

      this.SearchChat(e)
    }
  ngOnInit(): void {

     console.log(this.userData)


    // this.socket.on("show_chat",(data) => {
    //   console.log("CHATS = ", data );
    //   this.datas = data;
    //   setTimeout(function(){
    //     $("#content-box-chat").scrollTop($("#box-chat").height() );
    //   },1000);
      
    //   //console.log( $("#box-chat").height() );
    //  // console.log( `scroll height = ${document.getElementById("box-chat")}` )
    //  // document.getElementById("content-box-chat").scrollTop = document.getElementById("box-chat").scrollHeight// - document.getElementById("content-box-chat").clientHeight//document.getElementById("content-box-chat").clientHeight//.scrollHeight
    // });

    // this.socket.on("edit_message",(data) => {
    //   console.log("aquiiii");
    // });

  }
  
  testing(data){
    console.log(data)
  }

  ngAfterViewInit(): void{
    this.testing("xx");

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.tempChat = this.childMessage 

    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(changes)
    if(typeof changes.childMessage!="undefined" && changes.childMessage.firstChange!=true){
      this.test=this.datas.filter(o => o.id === changes.childMessage.currentValue);
      console.log(changes.childMessage.currentValue,"xxxx",this.test)
      this.status=true;
      
    }
    if(typeof changes.chatText!="undefined" && changes.chatText.firstChange!=true){
      this.band=true;
    }
      if(typeof changes.chatTexty!="undefined" && changes.chatTexty.firstChange!=true){
      this.test.push({
        id:this.user.id,
        name:this.user.name+" "+this.user.last_name,
        image:"/assets/images/default.png",
        message:changes.chatTexty.currentValue,
        message_id:100000000,
        hidden:true
      })
      this.band=false;
    }
        
    setTimeout(() => {
      this.container = document.getElementById('content-box-chat');
      this.container.scrollTop = this.container.scrollHeight;
    });
    
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      height: '500px',
      data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }
  find
  edit(message_id){
    console.log(message_id);
    document.getElementById("text-box-" + message_id).style.display = "block";

    /*document.getElementById("message"+message_id).style.visibility="hidden";
    this.find=this.test.filter(o => o.message_id === message_id?o.hidden=false:"");*/
    // this.find.hidden=false;    
  }

  delete(id){
    this.socket.deleteMessage(id);
    this.renderAgain()


    // this.socket.emit("delete_message",  { meetting_id : localStorage.getItem("meetting_id_current") , id : id });
  }
  renderAgain(){
   this.meeting_id = this.childMessage[0].meeting_id
   this.socket.getMessages(this.meeting_id);
   this.childMessage = [];
   const menssagesLast = this.socket.listenForLastChats().subscribe(
    (data: any[]) => {
      this.childMessage = data;
    },
    error => {
      console.error('Error en la solicitud HTTP:', error);
    }
  );
  }

  sendEdit(id){
    console.log( $(`#text-input-${id}`).val() );
    this.meeting_id = id;
    this.content= $(`#text-input-${id}`).val();
    this.socket.editMessage(this.meeting_id , this.content)
    this.renderAgain()
  //   this.socket.emit("edit_message", { id : id, message : $(`#text-input-${id}`).val() });
  //   //localStorage.setItem("meetting_id_current" , id );
  //   this.socket.emit("show_chat",{ meetting_id : localStorage.getItem("meetting_id_current") , user_id :  JSON.parse( localStorage.getItem("userData") ).id });
  }

  scroll(e){
    //e.target.scrollHeight
    //$("#content-box-chat").scrollTop( e.target.scrollHeight );

  }

  onFocus() {
    /*console.log('focus');
    this.showEmojiPicker = false;
    //this.boolInputSend = true;
    localStorage.setItem("boolInputSend","1");*/
  }

  onBlur() {
    console.log('onblur')
  }

}

// @Component({
//   selector: 'dialog-overview-example-dialog',
//   templateUrl: 'dialog-overview-example-dialog.html',
// })
// export class DialogOverviewExampleDialog {

//   constructor(
//     public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
//     @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

//   onNoClick(): void {
//     this.dialogRef.close();
//   }

// }

/*
$("#content-box-chat").on("scroll",function(e){
  console.log(e)
})*/