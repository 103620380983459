import { HostListener } from '@angular/core';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PlaceService } from 'src/app/services/place.service';
import { CourseWebsiteService } from "../../../services/course-website.service";
import { EventsService } from 'src/app/services/events.service';
import { environment } from "../../../../environments/environment";
import { GeneralService } from 'src/app/services/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})

export class TemplateComponent implements OnInit {
  @Input() screen;
  @Input() ids;
  isMobile: boolean = false;
  isTablet: boolean = false;
  searching
  results=10
  keyword=""
  lastId=null;
  urls=environment.storage;
  searchList=[]
  list: any = [];
  y: number;
  x: number;
  total: number;
  loading=true;
  limit=10;
  page=0;
  last_page=0;
  nomore=false;
  alldata=[]
  id: any;
  country_id:string = "";
  state_id:string = "";
  countries
  states
  status=0
  cities: any;
  show: boolean=false;
  category_id
  placecategories: any;
  level: any;
  allplaces: boolean=false;
  courseCategories = [];
   popularTopics = [];
  keywordCategory: string = "";
  keywordCity: string = "";
  keywordLevel: string = "";
  bandAllPlaces: boolean = false;
  processing: boolean = false;

  constructor(
    private CourseWebsiteService:CourseWebsiteService,
    private placeService:PlaceService,
    private generalService:GeneralService,
    private _router: Router,
    private route: ActivatedRoute,
    private EventsService:EventsService,
    private breakpointObserver: BreakpointObserver
  ) { }


  ngOnInit(): void {
    this.generalService.get_countries().subscribe(resp=>{
      this.status++;
      this.countries=resp.countries
    })
    const screen = this.route.snapshot.paramMap.get("screen");
    this.keyword = this.route.snapshot.paramMap.get("filter");
    /* Keywords mas buscados */
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => this.isMobile = result.matches);
    this.isTablet = !this.isMobile && window.innerWidth >= 600 && window.innerWidth <= 1023;
    if(this.keyword && this.keyword !== "") {
      this.refreshfilter();
    }
    if(screen == 'courses' || screen == 'cursos') {
      this.CourseWebsiteService.get_levels().subscribe(resp=>{
        this.level=resp.levels;
      })
      this.CourseWebsiteService.get_coursesCategories().subscribe(resp=>{
        this.courseCategories=resp.categories;
      })
    }

  }

  processLugares(resp, acc){
    if(!acc){//es acumulable o no los resultados
      this.alldata = [];
      this.lastId = null;
    }

    const respBack = resp.result;

    let countPlace = 0;
    if(respBack && respBack.length > 0){
      respBack?.forEach(element => {
        const seo = element.Place.toLowerCase().split(" ").join('-');
  
        const buscarPlace = this.alldata.find((af)=>af.id === element.id);
        if(buscarPlace){
          countPlace++;//para eliminar
        }
  
        if(!buscarPlace){
          this.alldata.push({
            name:element.Place,
            seo:seo,
            category:element.Category,
            description:element.description,
            main_image:element.main_image,
            address: (element.city&&element.municipality&&element.Country)?element.city+", "+element.municipality+", "+element.Country:"No asignado",
            teacher:null,
            level:null,
            aimed_to:null,
            evaluate:null,
            price:null,
            id:element.id
          })
          this.lastId=element.id;
        }
  
      });
    }
    
    if(respBack.length === 0){
      this.nomore = true;
    }else if(countPlace === respBack.length){
      this.nomore = true;
    }else{
      this.nomore = false;
    }
    this.popularTopics = resp.keyWords.map( elem => {
      return { text: elem, value: elem };
    });
    console.log("ultimo", this.lastId)
  }

  searchCourses(acc){
    if(!acc){
      this.lastId = null;
    }
    this.CourseWebsiteService.get_Listcourses(this.limit,this.lastId, this.keyword, this.keywordCategory, this.country_id, this.keywordLevel).subscribe(resp=>{
      if(resp.message_return.ERROR==false){
        this.loading=false;
        this.coursesdata(resp, acc)
      }
    })
  }

  searchLugares(acc){
    if(!acc){
      this.lastId = null;
    }
    console.log("this.lastId", this.lastId)

    this.placeService.get_listPlace(this.limit,this.lastId, this.keyword, this.keywordCategory, this.country_id).subscribe(resp=>{
      this.loading=false;
      this.processLugares(resp, acc)
    })
  }

  searchEventos(acc){
    if(!acc){
      this.lastId = null;
    }
    this.EventsService.get_event_pagination(this.limit,this.lastId, this.keyword, this.keywordCategory, this.country_id).subscribe(resp => {
      this.loading=false;
      this.processEvents(resp, acc);
    });
  }

  processEvents(resp, acc){

    if(!acc){//es acumulable o no los resultados
      this.alldata = [];
      this.lastId = null;
    }

    const respBack = resp.events;
    if(this.courseCategories.length === 0){
      this.courseCategories=resp.event_categories;

    }
    let countEvents = 0;
    if(respBack && respBack.length > 0){
      respBack?.forEach(element => {
        const seo = element.place.toLowerCase().split(" ").join('-');
  
        const buscarEvent = this.alldata.find((af)=>af.id === element.id);
        if(buscarEvent){
          countEvents++;//para eliminar
        }
  
        if(!buscarEvent){
          let aux = element.name.toLowerCase().replace(/\s/gi, "-");
          const start_date = this.format_list_data(element.start_date);
          this.alldata.push({
            name:element.name,
            seo:aux,
            category:element.category,
            description:element.description,
            main_image:element.main_image,
            address: (element.city&&element.state&&element.country)?element.city+", "+element.state+", "+element.country:"No asignado",
            teacher:null,
            level:null,
            aimed_to:null,
            evaluate:null,
            price:null,
            id:element.id_event,
            start_date: start_date,
            place: element.place,
            capacity: element.capacity,
            availability: element.availability
          })
          this.lastId=element.id_event;
        }
  
      });
    }
    console.log("respBack[0]",respBack[0])
    if(respBack.length === 0 || respBack[0] === undefined){
      this.nomore = true;
    }else if(countEvents === respBack.length){
      this.nomore = true;
    }else{
      this.nomore = false;
    }
    this.popularTopics = resp.keyWords.map( elem => {
      return { text: elem, value: elem };
    });




  }

  coursesdata(resp, acc){

    if(!acc){//es acumulable o no los resultados
      this.alldata = [];
      this.lastId = null;
    }

    this.loading=false;
    let countCourse = 0;
    if(resp.courses && resp.courses.length > 0){
      resp.courses.forEach(elem => {

        const buscarCourse = this.alldata.find((af)=>af.id === elem.idcourse);
        if(buscarCourse){
          countCourse++;//para eliminar
        }
  /////
        let aux = elem?.name?.toLowerCase().replace(/\s/gi, "-");
        const start_date = this.format_list_data(elem.start_date);
        if(!buscarCourse){
          this.alldata.push({
            name:elem.learn,
            seo:elem.level,
            category:elem.category,
            description:elem.description,
            main_image:elem.main_image,
            address:elem.place,
            teacher:elem.teachers,
            level:elem.level,
            aimed_to:elem.aimed_to,
            evaluate: elem.approved ? 'Evaluado' : 'No Evaluado',
            price:"$"+elem.price+" "+elem.currency,
            id:elem.idcourse,
            start_date: start_date,
            city: elem.city,
            country: elem.country,
            approved: elem.approved
          });
          this.lastId=elem.idcourse;
  
        }
        
  
      });
    }
    

    if(resp.courses.length === 0){
      this.nomore = true;
    }else if(countCourse === resp.courses.length){
      this.nomore = true;
    }else{
      this.nomore = false;
    }

    this.popularTopics = resp.keyWords.map( elem => {
      return {
        text: elem,
        value: elem
      }
    });
    console.log("ultimo", this.lastId)
            // this.searches();
  }


  Show(){
    if(this.show==true){
      this.show=false;
      this.status=0;

    }else{
      this.show=true;

    }
  }

  getState(){
    let id=this.country_id.split("-");
    this.generalService.get_getStatesByCountry(id[0]).subscribe(resp=>{
      this.states=resp.states;
      this.status++;
    })
    //this.keyword=id[1]
    this.searches()
  }

  getCity(){

    let id=this.state_id.split("-");
    this.generalService.get_getCitiesByState(id[0]).subscribe(resp=>{
      this.cities=resp.City;
      this.status++;
    })
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.searching=changes.screen.currentValue;
    this.id=changes.ids.currentValue;
    console.log("esto es searc", this.searching);
    this.refreshfilter();

  }

  refreshfilter(acc = false){
    if(this.searching === 'cursos'){
      this.searchCourses(acc);
    }else if(this.searching === 'lugares'){
      this.searchLugares(acc);
    }else if(this.searching === 'eventos'){
      this.searchEventos(acc);
    }
  }

  onWindowScroll(bandSearch = false) {
    this.refreshfilter(true)
  }

  format_list_data (date) {
    if(typeof date == 'string') {
      const arrayDate = date.split("-");
      return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
    }
    return date;
  }


  searches(){
    this.refreshfilter();
  }

  searchTopic(key) {
    this.keyword = key;
    this.refreshfilter();
  }
}
