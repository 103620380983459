import { Component, OnInit, Input,SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { DashboardComponent } from '../../dashboard.component';
import { Router } from '@angular/router';
import { NotificationsService } from "../../../services/notifications.service";
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from "../../../services/general.service";
import { SocketIoService } from '../../../services/socket-io.service';


interface PrivateChat {
  messages: any[];
  newNotifications: number
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  flag=true
  messages=[ ]
  notifications
  // =[
  //   {
  //     id:1,
  //     image:'apps',
  //     description:"Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:2,
  //     image:'account_box',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:3,
  //     image:'import_contacts',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:4,
  //     image:'account_box',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:1,
  //     image:'apps',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:2,
  //     image:'account_box',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   // {
  //   //   id:3,
  //   //   image:'import_contacts',
  //   //   description:"Lorem ipsum dolor sit amet, consectetur...",
  //   //   time:"1m"
  //   // },
  //   // {
  //   //   id:4,
  //   //   image:'account_box',
  //   //   description:"Lorem ipsum dolor sit amet, consectetur...",
  //   //   time:"1m"
  //   // },
  //   // {
  //   //   id:2,
  //   //   image:'account_box',
  //   //   description:"Lorem ipsum dolor sit amet, consectetur...",
  //   //   time:"1m"
  //   // },
  //   // {
  //   //   id:3,
  //   //   image:'import_contacts',
  //   //   description:"Lorem ipsum dolor sit amet, consectetur...",
  //   //   time:"1m"
  //   // },
    
    
  // ]
  total=0;
  constructor(public DashboardComponent: DashboardComponent,
    private _router: Router,
    private sanitizer:DomSanitizer,
    private NotificationsService:NotificationsService,
    private translate: TranslateService,
    private GeneralService:GeneralService,
    private socket: SocketIoService ,
    private cdr: ChangeDetectorRef,

    ) { 
    let datas=JSON.parse(localStorage.getItem('userData'))
      console.log(datas)
      let data={
        user_id:datas.id,
        limit:4
      }
      let aux=[];
      let text
      let text2

      this.NotificationsService.post_getActiveNotificationsByUserID(data).subscribe(resp=>{
        
        if(resp.message_return.ERROR==false){
          this.total=resp.notifications.length
          this.notifications=resp.notifications
          
          this.translate.get('notifications.time').subscribe( (text: string) => {
            moment.locale(text);
          });
          
          this.notifications = this.notifications.map((re) => {
            let time=moment(re.date).fromNow(true);
            return {
              image:re.notification_type,
              title:re.title,
              timer: `Hace ${time}`,//this.GeneralService.formatDate(time),
              status:re.Status,
              url:re.url,
              id: re.id
            };
          });
        }
      })
    }
    OpenChat(id):void{
      this.NotificationsService.SeenMessage(id)
      localStorage.setItem('SelectMeeting_id', id);
      this._router.navigate(['/dashboard/chat']);
    }
    NotificationMessages():void {
      const idUser = JSON.parse(localStorage.getItem("userData")).id;
    
      this.socket.getMeeting(idUser);

   this.socket.listenNotifications().subscribe((privateChats: PrivateChat) => {
    this.messages = privateChats.messages;
    console.log("desde el header dashboard")
    console.log( this.messages)

      });
    }

  ngOnInit() {
    this.NotificationMessages()
    this.messages =  this.NotificationsService.getMessages();
    this.socket.listenToServerEvents('sendMessagesPrivate').subscribe((data: any) => {
      if(data.user_id !==  JSON.parse( localStorage.getItem("userData") ).id){
        this.NotificationsService.AddMessage(data)
        this.messages =  this.NotificationsService.getMessages();

        this.cdr.detectChanges();
      }
  
    });
  }

  onSidenavToggle(){
    this.DashboardComponent.opened = this.DashboardComponent.opened ? false : true;
  }

  signOut() {
    // window.sessionStorage.clear();

    localStorage.setItem('userData', "");
    localStorage.setItem('menu_user', "");
    localStorage.setItem('dashCards', "");
    localStorage.setItem('access_token', "");
    this._router.navigateByUrl("/");

  }

  openNotificacion(index) {
    const selectNot = this.notifications[index];
    const aux = Object.assign({}, this.notifications);
    this.NotificationsService.put_notifications_status({ notification_id: selectNot.id }).subscribe(resp => {
      this.notifications = this.notifications.filter((el) => {
        return el.id != selectNot.id;
      });
      this.total = (this.total > 0) ? (this.total - 1) : 0;
      console.log(this.notifications, aux[index].url);
      window.location.href = aux[index].url;
    }, error => {
      console.log(error)
    })
}

}
