import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseWebsiteService } from "../../services/course-website.service";

@Component({
  selector: 'app-searching',
  templateUrl: './searching.component.html',
  styleUrls: ['./searching.component.scss']
})
export class SearchingComponent implements OnInit {
  screen
  flag: boolean=false;
  list: Object;
  id: string;
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
  ) { 
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {

    let data=this.route.snapshot.paramMap.get("screen");
    let data2=this.route.snapshot.paramMap.get("id");
    this.screen=data;
    this.id=data2==null?null:data2;
    console.log(data,data2);
    
    if(data!="courses"&&data!="cursos"&&data!="events"&&data!="eventos"&&data!="places"&&data!="lugares"&&data!="teachers"&&data!="instructores"){
      
      this._router.navigateByUrl("/");
    }
    
    
    if(data=="teachers"||data=="instructores"){
      // alert()
      this.flag=true;
    }else{
      this.flag=false;
    }

  }

}
