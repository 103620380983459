import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';
import { environment } from "../../../environments/environment";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})

export class EventDetailsComponent implements OnInit {
  start=5
  storage=environment.storage;
  load=false
  id: string;
  slug: string;  
  imagesCarousel: string[]= [];
  urlApi:string ;
  courses: any;
  resp: [];
  eventName: string;
  event: any;
  tags: string[] = [];
  user: any;
  teachers: any;
  schedule: any;
  evento : any;
  teacherId: any;
  url=[
    {
      url:"/home",
      urlName:"Home/"
    },
    {
      url:"",
      urlName:"Categoria/"
    },
    {
      url:"",
      urlName:""
    }
  ]
  star=3;
  videoId: string;
  afterViewInit: boolean = false;

  constructor(
    private EventsService:EventsService,
    private _route: ActivatedRoute,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.slug = this._route.snapshot.paramMap.get('id');
    const aux = this.slug.split("-");
    this.id = aux[0];
    this.EventsService.get_events(this.slug).subscribe(resp => {
      console.log("evento cargando")
      console.log(resp)
      const { Event, event_schedule, event_teacher } = resp;
      this.event = resp;

      if (this.event && this.event.Event && this.event.Event[0]) {
        this.imagesCarousel = resp.images.map(image => this.removeTrailingSlash(image));
        if (resp.Event[0].tags && typeof resp.Event[0].tags === 'string') {
          this.tags = resp.Event[0].tags.split(',').map(tag => tag.trim());
        } else {
          this.tags = [];
        } 
        this.schedule = event_schedule;
        this.teachers = event_teacher;
        this.evento =   Event

        for (let i = 0; i < this.teachers.length; i++) {

        this.teacherId = this.teachers[i].id

        }

        console.log(this.event);


        // Verificación adicional para asegurar que la propiedad 'urls' existe y tiene el formato esperado
        if (this.event.urls && typeof this.event.urls === 'string') {
          const url = this.event.urls.split("v=");
          this.videoId = url[1];
        }

        this.event.age = this.format_age(this.event.age);
        this.afterViewInit = true;
        this.load = true;
      }
    });
  }
  removeTrailingSlash(url: string): string {
    if (url && url.endsWith('/')) {
      return url.slice(0, -1); 
    }
    return url;
  }
  
  ngAfterViewInit(): void {
    //this.afterViewInit = true;
  }

  tagsArray(stringTags) {
    if (stringTags && typeof stringTags === 'string') {
      return stringTags.split(',');
    }
    return [];
  }
  

  format_date (date) {
    if(typeof date == 'string') {
      const arrayDate = date.split("-");
      return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
    }
    return date;
  }

  safeURL(){
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.videoId);
  }

  format_age (age) {
    if(Number.isInteger(age)) {
      return `Para mayores de ${age} años.`;
    }
    return age;
  }

}
