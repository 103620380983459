<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
    <div class="content">
        <app-header></app-header>
        <app-sub-header *ngIf="load" [screen]="1"></app-sub-header>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutGap="40px" class="containerCourseDetails">
            <div fxFlex="65%" fxLayout="column" fxLayoutAlign="start start stretch">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="">
                    <p *ngIf="load && event.Event && event.Event[0]" fxFlex class="title">{{event.Event[0].name}} </p>

                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="">
                    <p *ngIf="load" fxFlex class="subtitle">{{event.Event[0].category}} </p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="">
                    <p *ngIf="load" fxFlex class="description">{{event.Event[0].description}}</p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <mat-chip-list aria-label="Fish selection" class="space-image">
                        <mat-chip *ngFor="let item of tags">{{item}}</mat-chip>
                    </mat-chip-list>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" style="width: 100%;">
                    <div class="row" style="width: 100%;">
                        <div class="col-md-12 col-xs-12">
                            <app-slide-course-details *ngIf="load" [images]="imagesCarousel"></app-slide-course-details>
                        </div>
                    </div>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end" fxLayoutGap.gt-sm="10px" class="sectionSocial">
                    <p class="space" fxFlex>Compartir </p>
                    <img class="pointer" href="#" src="/assets/images/facebook_copia.png" width="25px" alt="Facebook">
                    <img class="pointer" href="#" src="/assets/images/twitter_copia_2.png" width="25px" alt="Twitter">
                    <img class="pointer" href="#" src="/assets/images/link_copia.png" width="25px" alt="Enlace">
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="width: 100%;">
                    <mat-tab-group>
                        <mat-tab label="Video">
                            <div class="long-tabs">
                                <youtube-player *ngIf="afterViewInit" [videoId]="videoId" suggestedQuality="highres" width="100%" height="500px">
                                </youtube-player>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
            <div fxFlex="35%" fxLayout="column" fxLayoutAlign="start start stretch" class="sidebarRight">
                <!--div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="box-discount">
                        <p *ngIf="load" class="p-space prices-orange" fxFlex>
                            10% Dto. $ 10 USD
                        </p>
                    </div-->
                <div fxLayout="row" fxLayout.lt-md="column" class="gray-box box-price" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <p *ngIf="load" class="p-space prices-big" fxFlex>
                        $ {{event.price}} USD
                    </p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="center-white">
                    <a fxFlex routerLink="/cart" mat-button class="example-icon favorite-icon style-login registrate btn-event" style="margin: 0px 20px;">
                            Inscribete en este evento
                        </a >
                        <a fxFlex routerLink="/cart" mat-button
                            class="example-icon favorite-icon style-login registrate2 add-cart"
                            style="margin: 0px 20px;"
                            matBadge="1" matBadgePosition="after" matBadgeColor="warn"
                        >
                            <mat-icon>add_shopping_cart</mat-icon>
                        </a >
                        <a fxFlex routerLink="/cart" mat-button
                            class="example-icon favorite-icon style-login registrate2"
                            style="margin: 0px 20px;"
                        >
                            <mat-icon>favorite_border</mat-icon>
                        </a >
                    </div>
                    <div style="padding-bottom: 30px;">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="height: 31px;">
                            <span  class="date">Fecha de Evento: </span><span class="date-bold" *ngIf="load">{{format_date(event.start_date)}}</span>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                            <span  class="date">Edad: </span><span class="date-bold" *ngIf="load">{{event.age}}</span>
                        </div>
                        <!--div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                            <span  class="date">Nivel: </span><span *ngIf="load" class="date-bold">Basico</span>
                        </div-->
                        <!--div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="padding-bottom: 30px;">
                            <span class="date">Evaluado: </span><span class="date-bold">Si</span>
                        </div-->
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="gray-box">
                        <p *ngIf="load" class="p-space academy">Acerca del Evento</p>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="40px" class="sideUser">
                        <div fxFlex="20%" fxLayout="column" fxLayoutAlign="space-between stretch" >
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="userAvatar">
                                <img *ngIf="load" src="/assets/images/default.png" width="100%" alt="">
                            </div>
                        </div>
                        <div fxFlex="80%" fxLayout="column" fxLayoutAlign="center start stretch" class="width-full-side">
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                                <p *ngIf="load" fxFlex class="address-user" >Responsable:</p>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                                <p fxFlex class="name-user" *ngFor="let teacher of teachers">{{ teacher.name }}  {{ teacher.last_name }} </p>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                                <p *ngIf="load" fxFlex class="address-user" >San Cristobal, Venezuela</p>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                                <div fxFlex="80%" fxLayout="column" fxLayoutAlign="end center">

                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                                        <span *ngIf="load" fxFlex [ngSwitch]="star" class="start-rigth">
                                            <span class="star" *ngSwitchCase="0"><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                            <span class="star" *ngSwitchCase="1"><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                            <span class="star" *ngSwitchCase="2"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                            <span class="star" *ngSwitchCase="3"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                            <span class="star" *ngSwitchCase="4"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon></span>
                                            <span class="star" *ngSwitchCase="5"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
                                        </span>
                                    </div>
                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="width-full-side" >
                                        <a class="start-rigth width-full-side link-start" href="/detail/teacher-public/ {{ teacherId }}">Ver Calificaciones</a>

                </div>
            </div>
        </div>
    </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="40px" class="sideUser" style="margin-top: 0;">
        <div fxFlex="20%" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="userAvatar">
                <img *ngIf="load" src="/assets/images/default.png" width="100%" alt="">
            </div>
        </div>
        <div fxFlex="80%" fxLayout="column" fxLayoutAlign="center start stretch" class="width-full-side">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                <p *ngIf="load" fxFlex class="address-user">Lugar:</p>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                <p *ngIf="load" fxFlex class="name-user" style="color:black; font-weight: 900 !important;">{{event.place}}</p>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                <p *ngFor="let evento of evento" fxFlex class="address-user">
                    {{ evento.place}}
                </p>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                <div fxFlex="80%" fxLayout="column" fxLayoutAlign="end center">

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                        <span *ngIf="load" fxFlex [ngSwitch]="star" class="start-rigth">
                                            <span class="star" *ngSwitchCase="0"><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                        <span class="star" *ngSwitchCase="1"><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                        <span class="star" *ngSwitchCase="2"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                        <span class="star" *ngSwitchCase="3"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                        <span class="star" *ngSwitchCase="4"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon></span>
                        <span class="star" *ngSwitchCase="5"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
                        </span>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                        <a class="start-rigth width-full-side link-start" href="">Ver Calificaciones</a>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="gray-box">
        <p class="p-space academy">Acerca del Anfitrion</p>
    </div>
    <!-- Profesores--->
    <div class="pt-teachers pb-teachers">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="40px" class="sideUser" *ngFor="let teacher of teachers" routerLink="/bio-teacher/1" title="Ver bio de Jose Rojas">
            <div fxFlex="20%" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="userAvatar">
                    <img *ngIf="load" src="/assets/images/default.png" width="100%" alt="">
                </div>
            </div>
            <div fxFlex="80%" fxLayout="column" fxLayoutAlign="center start stretch" class="width-full-side">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                    <p *ngIf="load" fxFlex class="name-teacher">Jose Rojas</p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                    <p *ngIf="load" fxFlex class="teacher-carrer">Ingeniero</p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                    <mat-chip-list aria-label="Fish selection">
                        <mat-chip *ngFor="let item of tagsArray(tags)">{{item}}</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
            <p *ngIf="load && teachers.length == 0" class="itemTeacher">
                <mat-icon class="icon-s">fiber_manual_record</mat-icon> No Disponible</p>
        </div>
    </div>

    <!-- Fin de Profesores -->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="gray-box">
        <p class="p-space academy">Horario</p>
    </div>
    <div class="pt-schedule pb-schedule">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
            <p *ngIf="load && schedule.length > 0" class="itemSchedule">
                <mat-icon class="icon-s">fiber_manual_record</mat-icon> Fecha de Inicio 10/10/2020: 10:00 - 15:00</p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
            <p *ngIf="load && schedule.length > 0" class="itemSchedule">
                <mat-icon class="icon-s">fiber_manual_record</mat-icon> Fecha de Fin 10/10/2020: 10:00 - 15:00</p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
            <p *ngIf="load && schedule.length == 0" class="itemSchedule">
                <mat-icon class="icon-s">fiber_manual_record</mat-icon> No Disponible</p>
        </div>
    </div>
    </div>
    </div>
    <app-footer></app-footer>
    </div>
</mat-drawer-container>