import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {
  isMobile:boolean = false;

  @Input() screen
  searching;
  
  constructor(private breakpointObserver: BreakpointObserver) {}


  ngOnInit(): void {
    const CustomBreakpoints = {
      Handset: '(max-width: 599px)', // Mobile devices up to 599px
    };
    this.breakpointObserver.observe([CustomBreakpoints.Handset])
    .subscribe(result => this.isMobile = result.matches);
  }

ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.screen.currentValue)
    this.searching=changes.screen.currentValue;
    
  }
}
