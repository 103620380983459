import { Component, OnInit } from '@angular/core';
import { AffiliateService } from '../../services/affiliate.service';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})

export class CartComponent implements OnInit {
  data=[];
  selects=[
    {
      value: 0,
      viewValue: 'Afiliado'
    }
  ]
  selected = 0
  cart: any;
  counter = 1;

  constructor(private AffiliateService:AffiliateService, private CartService:CartService) { }

  ngOnInit(): void {
    this.loadAffiliates();
    this.refreshCart();
  }

  loadAffiliates() {
    const data = JSON.parse(localStorage.getItem('userData'));
    this.AffiliateService.get_admin_useraffiliates(data.id).subscribe( res => {
      const { affiliate } = res;
      this.selects = this.selects.concat(affiliate.map((elem) => {
        return {
          value: elem.affiliate_id,
          viewValue: `${elem.name} ${elem.last_name}`
        }
      }));
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  refreshCart() {
    let cart = JSON.parse(localStorage.getItem('cartData'));
    cart = (Array.isArray(cart) && cart.length > 0) ? cart[0] : 0;
    this.cart = cart;
    if(cart) {
      this.CartService.get_admin_getCartList(cart.cartID).subscribe( res => {
        const { listCart } = res;
        const { cartID } = res;
        if(cartID != 0 && listCart != undefined) {
          const { courses, events } = listCart;
          if(Array.isArray(courses) && courses.length > 0) {
            this.data = courses.map( elem => {
              const rAr = elem['amount Per Unity'].split(" ");
              const price = {
                currency: rAr[1],
                value: parseFloat(rAr[0])
              };
              return {
                id: elem.id,
                title: elem.product,
                category: "Ninguna",
                percentage:`${elem.discount}% Dto. `,
                pricePP: elem['amount Per Unity'],
                price:elem.amountpp,
                priceObj: price,
                place:{
                  name:elem.place,
                  own: null
                },
                address:`${elem.city}`,
                dateStart:"00/00/0000",
                level:{
                  name: elem.level,
                  target:`Para mayores de ${elem.age} años`,
                  approved: null
                },
                quantity: elem.quantity,
                img: elem.image, 
                type: 'course',
                affiliate: elem.affiliate ? elem.affiliate : 'No Disponible'
              }
            });
          } else {
            this.data = [];
          }
          if(Array.isArray(events) && events.length > 0) {
            this.data = this.data.concat(events.map( elem => {
              return {
                ...elem,
                type: 'event'
              };
            }));
          }
        } else {
          this.data = [];
        }
      }, err => {
        alert(err.error.message_return.ERROR_MENSAGGE);
      });
    } else {
      this.data = [];
    }
  }

  updateItem(id, data) {
    this.CartService.put_admin_products(id, data).subscribe( res => {
      console.log(res);
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  affiliateChange(e) {

  }

  deleteItem(id) {
    const data = this.cart;
    this.CartService.delete_admin_products(id, data).subscribe( res => {
      this.data = this.data.filter( (elem) => {
        return elem['id'] != id;
      });
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  total() {
    let calc = 0;
    for(let i = 0; i < this.data.length; i++) {
      calc+=this.data[i]['priceObj'].value;
    }
    return `${this.data[0]['priceObj'].currency} ${calc.toFixed(2)}`;
  }

  saveTotal() {
    const totalString = this.total();
    const rAr = totalString.split(" ");
    const totalCart = {
      currency: rAr[0],
      value: parseFloat(rAr[1])
    };
    localStorage.setItem('totalCart', JSON.stringify(totalCart));
  }

  counterDe() {
    if(this.counter > 0)
      this.counter--;
  }

  counterIn() {
    this.counter++;
  }
}
