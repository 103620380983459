import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { DashboardRoutingModule } from './dashboard/dashboard-routing.module';
import { WebsiteComponent } from './website/website.component';
import { IndexComponent } from './website/index/index.component';
import { FirstStepsComponent } from './website/first-steps/first-steps.component';
import { SearchingComponent } from './website/searching/searching.component';
import { AboutUsComponent } from './website/about-us/about-us.component';
import { TeacherFrontendComponent } from './website/teacher-frontend/teacher-frontend.component';
import { BusinessFrontendComponent } from './website/business-frontend/business-frontend.component';
import { CartComponent } from './website/cart/cart.component';
import { CourseDetailsComponent } from './website/course-details/course-details.component';
import { ContactComponent } from './website/contact/contact.component';
import { TermConditionComponent } from './website/term-condition/term-condition.component';
import { PaymentProccessComponent } from './website/payment-proccess/payment-proccess.component';
import { ConfirmBuyComponent } from './website/confirm-buy/confirm-buy.component';
import { PlaceDetailsComponent } from './website/place-details/place-details.component';
import { BioTeacherComponent } from './website/bio-teacher/bio-teacher.component';
import { EventDetailsComponent } from './website/event-details/event-details.component';
import { TestComponent } from './test/test.component';
import { DetailsTeacherComponent } from './website/searching/teachers/details-teachers-public/details-teachers.component';


const routes: Routes = [
  // { path:'', },
  // { path:'hola', loadChildren: () => import(`./website/website.module`).then(m => m.WebsiteModule) },
  {path:'maps',component:TestComponent},

  {path:'',component:WebsiteComponent},
  {path:'maps', component:TestComponent},
  {path:'search/:screen',component:SearchingComponent},
  {path:'search/:screen/:id',component:SearchingComponent},
  {path:'search/:screen/all/:filter',component:SearchingComponent},
  {path:'first-steps',component:FirstStepsComponent},
  {path:'about-us',component:AboutUsComponent},
  {path:'teacher-info',component:TeacherFrontendComponent},
  {path:'business-info',component:BusinessFrontendComponent},
  {path:'course/:id',component:CourseDetailsComponent},
  {path:'place/:id',component:PlaceDetailsComponent},
  {path:'event/:id',component:EventDetailsComponent},
  {path:'bio-teacher/:id',component:BioTeacherComponent},////
  {path:'contact',component:ContactComponent},
  {path:'cart',component:CartComponent},
  {path:'confirm-buy',component:ConfirmBuyComponent},
  {path:'term-condition',component:TermConditionComponent},
  {path:'payment',component:PaymentProccessComponent},
  {path:'privacy-policies',component:TermConditionComponent},

  {path: 'detail/teacher-public/:id', component: DetailsTeacherComponent},
  { path: 'auth/login', loadChildren: () => import(`./auth/auth.module`).then(m => m.AuthModule) },
  { path: '', pathMatch: 'full', redirectTo: '' },
  // { path: '/auth/login', redirectTo: '/auth/login' },
  { path: '**', pathMatch: 'full', redirectTo: '' },
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    AuthRoutingModule,
    DashboardRoutingModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }