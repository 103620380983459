import { Component, OnInit } from '@angular/core';
import { CardService } from '../../services/card.service';
import { AddressService } from '../../services/address.service';
import { GeneralService } from '../../services/general.service';
import { CartService } from '../../services/cart.service';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-confirm-buy',
  templateUrl: './confirm-buy.component.html',
  styleUrls: ['./confirm-buy.component.scss']
})

export class ConfirmBuyComponent implements OnInit {

  listCards = [
    /*{
      value: 'visa',
      img: 'card-visa.png',
      card: '4242 **** **** **42',
      name: 'Jose Perez',
      date: 'MM/AA',
      codeSeg: '***',
      country: 'Venezuela',
      code: '5001'
    },
    {
      value: 'mastercard',
      img: 'card-mastercard.png',
      card: '4242 **** **** **42',
      name: 'Jose Perez',
      date: 'MM/AA',
      codeSeg: '***',
      country: 'Venezuela',
      code: '5001'
    }*/
  ];
  selectCard: string;
  typeAddCard: string = 'add-card';
  showAddCard: boolean = false;
  selectAddress: string = "";
  listAddress = [
    {
      value: "valid",
      text: "Venezuela, Tachira, San Cristobal. 5001. La Concordia Calle Carrera ..."
    }
  ];
  typeAddNewAddress: string = 'add-address';
  showAddAddress: boolean = false;
  listCountry = [];
  selectCountry: string = "";
  listCity = [];
  selectCity: string = "";
  listState = [];
  selectState: string = "";
  typePaymentCard: string = 'card';
  typePaymentPaypal: string = 'paypal';
  typePayment: string = this.typePaymentCard;
  selectPaypal: string;
  listPaypal = [
    {
      value: 'paypal-1',
      img: 'card-paypal.png',
      email: 'correo@web.com'
    }
  ];
  typeAddPaypal: string = 'add-paypal';
  bandCountry: boolean = false;
  saveAccount = false;
  termAndCond = false;
  totalObj: any;
  totalString: string;
  matcher = new MyErrorStateMatcher();
  nroCardFormControl = new FormControl('', [Validators.required, Validators.pattern('([0-9]*){16}')]);
  nameCardFormControl = new FormControl('', [Validators.required, Validators.pattern('([A-Za-z. ]*)+')]);
  dateExpFormControl = new FormControl('', [Validators.required, Validators.pattern('((0[1-9]{1}|1[0-2]{1})/[0-9]{2})')]);
  cvcFormControl = new FormControl('', [Validators.required, Validators.pattern('([0-9]{3})')]);
  addressFormControl = new FormControl('', [Validators.required]);
  countryFormControl = new FormControl('', []);
  stateFormControl = new FormControl('', []);
  cityFormControl = new FormControl('', []);
  codeFormControl = new FormControl('', []);
  addressLgFormControl = new FormControl('', []);
  errors = {
    nroCardFormControl: { msg: { required: "Nro. de Tarjeta es requerido.", pattern: "Nro. de Tarjeta solo debe contener números, y debe contener 16 caracteres."} },
    nameCardFormControl: { msg: { required: "Nombre del Titular de la tarjeta es requerido.", pattern: 'Nombre del Titular de la tarjeta debe contener solo letras,". ".'} },
    dateExpFormControl: { msg: { required: "Fecha de Expiración es requerido.", pattern: 'Fecha de Expiración debe tener el formato "MM/AA", ej. 12/20'} },
    cvcFormControl: { msg: { required: "CVC es requerido.", pattern: 'CVC solo debe contener números, y contener máximo 3 caracteres.'} },
    addressFormControl: { msg: { required: "Dirección es requerido."} },
    countryFormControl: { msg: { required: "País es requerido." } },
    stateFormControl: { msg: { required: "Estado es requerido." } },
    cityFormControl: { msg: { required: "Ciudad es requerido." } },
    codeFormControl: { msg: { required: "Código Postal es requerido.", pattern: 'Código Postal solo debe contener números, y contener máximo 5 caracteres.'} },
    addressLgFormControl: { msg: { required: "Dirección es requerido."} }
  };

  constructor(private CardService:CardService, private AddressService:AddressService, private GeneralService:GeneralService, private CartService:CartService) { }

  ngOnInit(): void {
    this.loadCards();
    this.loadPaypal();
    this.loadAddress();
    this.loadTotal();
  }

  radioChange(e){
    if(this.selectCard === this.typeAddCard) {
      this.showAddCard = true;
    } else {
      this.showAddCard = false;
    }
  }

  selectChange(e){
    if(this.selectAddress === this.typeAddNewAddress) {
      if(!this.bandCountry)
        this.loadCountry();
      this.setValidatorRequired();
      this.showAddAddress = true;
    } else {
      this.clearValidator();
      this.showAddAddress = false;
    }
  }
  
  setValidatorRequired() {
    this.countryFormControl.setValidators([Validators.required]);
    this.countryFormControl.updateValueAndValidity();
    this.stateFormControl.setValidators([Validators.required]);
    this.stateFormControl.updateValueAndValidity();
    this.cityFormControl.setValidators([Validators.required]);
    this.cityFormControl.updateValueAndValidity();
    this.codeFormControl.setValidators([Validators.required, Validators.pattern('([0-9]{4,5})')]);
    this.codeFormControl.updateValueAndValidity();
    this.addressLgFormControl.setValidators([Validators.required]);
    this.addressLgFormControl.updateValueAndValidity();
  }

  clearValidator() {
    this.countryFormControl.clearValidators();
    this.countryFormControl.updateValueAndValidity();
    this.stateFormControl.clearValidators();
    this.stateFormControl.updateValueAndValidity();
    this.cityFormControl.clearValidators();
    this.cityFormControl.updateValueAndValidity();
    this.codeFormControl.clearValidators();
    this.codeFormControl.updateValueAndValidity();
    this.addressLgFormControl.clearValidators();
    this.addressLgFormControl.updateValueAndValidity();
  }

  activeErrors() {
    if(!this.nroCardFormControl.valid || !this.nameCardFormControl.valid || !this.dateExpFormControl.valid ||
       !this.cvcFormControl.valid ||  !this.addressFormControl.valid || !this.countryFormControl.valid || 
       !this.stateFormControl.valid || !this.cityFormControl.valid || !this.codeFormControl.valid ||
       !this.addressLgFormControl.valid ) {
      Object.keys(this.errors).forEach(key => {
        this[key].touched = true;
      });
      return true;
    }
    return false;
  }

  openPayment(type) {
    this.typePayment = type;
  }

  radioChangePaypal(e) {
    console.log(this.selectPaypal);
  }

  loadCards(){
    const data = JSON.parse(localStorage.getItem('userData'));
    this.CardService.get_cards(data.id).subscribe( res => {
      const { cards } = res;
      this.listCards = cards.map( item => {
        return {
          value: item.id,
          img: item.provider == "visa" ? 'card-visa.png' : 'card-mastercard.png',
          card: `${item.card_number}************`,
          name: item.card_name,
          date: item.expiration_date,
          codeSeg: '***',//item.CVC,
          country: item.country,
          code: item.zip_code
        };
      });
      console.log(cards);
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  loadPaypal() {
    console.log();
  }

  loadAddress(){
   const data = JSON.parse(localStorage.getItem('userData'));
    this.AddressService.get_addresses(data.id).subscribe( res => {
      const { address } = res;
      this.listAddress = address.map( item => {
        return {
          value: item.address_id,
          text: `${item.country}, ${item.state}, ${item.city}. ${item.zip_code}. ${item.address}...`
        };
      });
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    }); 
  }

  loadTotal() {
    const totalObj = JSON.parse(localStorage.getItem('totalCart'));
    this.totalObj = totalObj;
    this.totalString = totalObj ? `${totalObj.currency} ${totalObj.value.toFixed(2)}` : "USD 0";
  }

  loadCountry(){
      this.GeneralService.get_countries().subscribe( res => {
        const { countries } = res;
        this.listCountry = countries.map( elem => {
          return { value: elem.id, text: elem.name }
        });
        this.bandCountry = true;
      }, err => {
        alert(err.error.message_return.ERROR_MENSAGGE);
      });
  }

  selectedState() {
    this.GeneralService.get_getStatesByCountry(this.selectCountry).subscribe( res => {
      const { states } = res;
      this.listState = states.map( elem => {
        return {
          value: elem.id,
          text: elem.state_name
        }
      });
      this.selectState = "";
      this.listCity = [];
      this.selectCity = "";
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  selectedCity() {
    this.GeneralService.get_getCitiesByState(this.selectState).subscribe( res => {
      const { City } = res;
      this.listCity = City.map( elem => {
        return {
          value: elem.id,
          text: elem.city
        }
      });
      this.selectCity = "";
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  paymentCompleted() {
    
      let data = {};
      const user = JSON.parse(localStorage.getItem('userData'));
      const cart = JSON.parse(localStorage.getItem('cartData'));
      if(this.termAndCond) {
        let address = 0;
        if(this.selectAddress == this.typeAddNewAddress && !this.activeErrors()) { 
          data = {
            "address": this.addressLgFormControl.value,
            "main_address": 0,
            "country_id": this.countryFormControl.value,
            "state_id": this.stateFormControl.value,
            "city_id": this.cityFormControl.value,
            "county_id": 0,
            "parish_id": 0,
            "zip_code": this.codeFormControl.value,
            "user_id": user.id
          };
          this.AddressService.post_adminaddresses(data).subscribe( res => {
            console.log(res);
            if(this.saveAccount && !this.activeErrors()) {
              data = {
                card_name: this.nameCardFormControl.value,
                card_number: this.nroCardFormControl.value,
                expiration_date: this.dateExpFormControl.value,
                CVC: this.cvcFormControl.value,
                billing_address_id: 0,//No me retorna el id
                type: this.typePayment == this.typePaymentCard ? 1 : 0,
                provider: "visa",
                user_id: user.id
              }
              this.CardService.post_admincards(data).subscribe( res => {
                console.log(res);
              }, err => {
                alert(err.error.message_return.ERROR_MENSAGGE);
              });
            }
          }, err => {
            alert(err.error.message_return.ERROR_MENSAGGE);
          });
        } else {
          address = this.addressFormControl.value;
          if(this.saveAccount && !this.activeErrors()) {
            data = {
              card_name: this.nameCardFormControl.value,
              card_number: this.nroCardFormControl.value,
              expiration_date: this.dateExpFormControl.value,
              CVC: this.cvcFormControl.value,
              billing_address_id: address,
              type: this.typePayment == this.typePaymentCard ? 1 : 0,
              provider: "visa",
              user_id: user.id
            }
            this.CardService.post_admincards(data).subscribe( res => {
              console.log(res);
            }, err => {
              alert(err.error.message_return.ERROR_MENSAGGE);
            });
          }
        }
        if(this.selectCard) {
          const date = new Date();
          const month = date.getMonth() < 8 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1);
          data = {
            order_date: `${date.getFullYear()}-${month}-${date.getDate()}`,
            order_Name: "Nombre de la orden Test",
            order_CartID: cart[0].cartID,
            order_status_id: 1,
            email: user.email,
            cart: 0,
          };
          this.CartService.post_admin_orders(data).subscribe( res => {
            console.log(res);
            alert('Exito..!!!');
          }, err => {
            alert(err.error.message_return.ERROR_MENSAGGE);
          });
        }
      } else {
        alert('Debes aceptar los terminos y condiciones de Sogcial...!');
      }
    
  }
}
