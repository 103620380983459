<mat-drawer-container
  class="drawer-container"
  style="overflow: hidden !important"
>
  <div class="content">
    <app-header></app-header>
    <app-sub-header [screen]="1"></app-sub-header>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between none"
      fxLayoutGap="40px"
      class="containerPlaceDetails"
    >
      <div fxFlex="65%" fxLayout="column" fxLayoutAlign="start start stretch">
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between stretch"
          fxLayoutGap.gt-sm=""
        >
          <p *ngIf="load" fxFlex class="title">{{ title }}</p>
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between stretch"
          fxLayoutGap.gt-sm=""
        >
          <p *ngIf="load" fxFlex class="subtitle">
            <span class="minuscula"
              >{{ categoryM }}
              <span *ngIf="subCategory">> {{ subCategoryM }}</span></span
            >
          </p>
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between stretch"
          fxLayoutGap.gt-sm=""
        >
          <p fxFlex class="description">{{ description }}</p>
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="center center stretch"
          fxLayoutGap.gt-sm="20px"
          class="containerSlider"
        >
          <div class="row" style="width: 100%">
            <div class="col-md-12 col-xs-12" style="padding: 0">
              <app-slide-course-details
                *ngIf="load"
                [images]="carousel"
              ></app-slide-course-details>
            </div>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="end end"
          fxLayoutGap.gt-sm="10px"
          class="sectionSocial"
        >
          <p class="space" fxFlex>Compartir</p>
            <img class="pointer" href="#" src="/assets/images/facebook_copia.png" width="25px" alt="Facebook">
           <img class="pointer" href="#"  src="/assets/images/twitter_copia_2.png" width="25px" alt="Twitter">  
           <img class="pointer" href="#" src="/assets/images/link_copia.png" width="25px" alt="Enlace">  
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between stretch"
          fxLayoutGap.gt-sm="20px"
          style="width: 100%"
        >
          <mat-tab-group *ngIf="load" style="width: 100%">
            <mat-tab label="Descripcion">
              <div class="long-tabs">
                <p *ngIf="load" class="itemTab">
                  <mat-icon class="icon-s">fiber_manual_record</mat-icon>
                  Capacidad: {{ place?.capacity }}
                </p>
                <p *ngIf="load" class="itemTab">
                  <mat-icon class="icon-s">fiber_manual_record</mat-icon>
                  Wifi: {{ wifi ? "Disponible" : "No Disponible" }}
                </p>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div
        fxFlex="35%"
        fxLayout="column"
        fxLayoutAlign="start start stretch"
        class="sidebarRight"
      >
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between stretch"
          fxLayoutGap.gt-sm="40px"
          class="sideUser"
        >
          <div
            fxFlex="20%"
            fxLayout="column"
            fxLayoutAlign="space-between stretch"
          >
            <div
              fxLayout="row"
              fxLayout.lt-md="column"
              fxLayoutAlign="space-between stretch"
              fxLayoutGap.gt-sm="20px"
              class="userAvatar"
            >
              <img
                *ngIf="load"
                src="/assets/images/default.png"
                width="100%"
                alt=""
              />
            </div>
          </div>
          <div
            fxFlex="80%"
            fxLayout="column"
            fxLayoutAlign="center start stretch"
            class="width-full-side"
          >
            <div
              fxLayout="row"
              fxLayout.lt-md="column"
              fxLayoutAlign="end end stretch"
              fxLayoutGap.gt-sm="20px"
              class="width-full-side"
            >
              <p *ngIf="load" fxFlex class="name-user">{{ nameUser }}</p>
            </div>
            <div
              fxLayout="row"
              fxLayout.lt-md="column"
              fxLayoutAlign="end end stretch"
              fxLayoutGap.gt-sm="20px"
              class="width-full-side"
            >
              <p *ngIf="load" fxFlex class="address-user">
                {{ city }}, estado {{ state }}
              </p>
            </div>
            <div
              fxLayout="row"
              fxLayout.lt-md="column"
              fxLayoutAlign="start start stretch"
              fxLayoutGap.gt-sm="20px"
              class="width-full-side"
            >
              <div fxFlex="80%" fxLayout="column" fxLayoutAlign="end center">
                <div
                  fxLayout="row"
                  fxLayout.lt-md="column"
                  fxLayoutAlign="start start stretch"
                  fxLayoutGap.gt-sm="20px"
                  class="width-full-side"
                >
                  <span
                    *ngIf="load"
                    fxFlex
                    [ngSwitch]="star"
                    class="start-rigth"
                  >
                    <span class="star" *ngSwitchCase="0"
                      ><mat-icon>star_border</mat-icon
                      ><mat-icon>star_border</mat-icon
                      ><mat-icon>star_border</mat-icon
                      ><mat-icon>star_border</mat-icon
                      ><mat-icon>star_border</mat-icon></span
                    >
                    <span class="star" *ngSwitchCase="1"
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_border</mat-icon
                      ><mat-icon>star_border</mat-icon
                      ><mat-icon>star_border</mat-icon
                      ><mat-icon>star_border</mat-icon></span
                    >
                    <span class="star" *ngSwitchCase="2"
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_border</mat-icon
                      ><mat-icon>star_border</mat-icon
                      ><mat-icon>star_border</mat-icon></span
                    >
                    <span class="star" *ngSwitchCase="3"
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_border</mat-icon
                      ><mat-icon>star_border</mat-icon></span
                    >
                    <span class="star" *ngSwitchCase="4"
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_border</mat-icon></span
                    >
                    <span class="star" *ngSwitchCase="5"
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_rate</mat-icon
                      ><mat-icon>star_rate</mat-icon></span
                    >
                  </span>
                </div>
                <div
                  fxLayout="row"
                  fxLayout.lt-md="column"
                  fxLayoutAlign="space-between stretch"
                  fxLayoutGap.gt-sm="20px"
                  class="width-full-side"
                >
                  <p class="start-rigth width-full-side link-start">
                    Ver Calificaciones
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between stretch"
          fxLayoutGap.gt-sm="20px"
          class="gray-box"
        >
          <p class="p-space academy">Ubicación</p>
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between stretch"
          fxLayoutGap.gt-sm="20px"
        >
          <p *ngIf="load" class="sideAddress">
            {{ city }} - {{ zone }} - {{ zipCode }}<br />
            {{ address }}
          </p>
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between stretch"
          fxLayoutGap.gt-sm="20px"
          class="sideMap"
        >
          <agm-map [latitude]="lat" [zoom]="15" [longitude]="lng">
            <agm-marker
              *ngFor="let m of markers; let i = index"
              [latitude]="m.lat"
              [longitude]="m.lng"
              [markerDraggable]="draggable"
            ></agm-marker>
          </agm-map>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between stretch"
          fxLayoutGap.gt-sm="20px"
          class="gray-box"
        >
          <p class="p-space academy">Contacto</p>
        </div>
        <div class="pt-socialNetwork pb-socialNetwork">
          <div
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="end end stretch"
            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/phone.png"
              width="15"
              class="icon-c"
              alt="Teléfono"
            />
            <p *ngIf="load" fxFlex class="icon-text pt-icon">
              {{ phone }} <span *ngIf="phone_2">- {{ phone_2 }}</span>
            </p>
          </div>
          <div
            *ngIf="email"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="end end stretch"
            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/email.png"
              width="15"
              class="icon-c"
              alt="Correo Electrónico"
            />
            <p *ngIf="load" fxFlex class="icon-text">{{ email }}</p>
          </div>
          <div
            *ngIf="web"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="end end stretch"
            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/web.png"
              width="14"
              class="icon-c"
              alt="Web"
            />
            <p *ngIf="load" fxFlex class="icon-text">{{ web }}</p>
          </div>
          <div
            *ngIf="facebook"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="end end stretch"
            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/facebook.png"
              width="15"
              class="icon-c"
              alt="facebook"
            />
            <p *ngIf="load" fxFlex class="icon-text">{{ facebook }}</p>
          </div>
          <div
            *ngIf="twitter"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="end end stretch"
            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/twitter.png"
              width="15"
              class="icon-c"
              alt="Twitter"
            />
            <p *ngIf="load" fxFlex class="icon-text">{{ twitter }}</p>
          </div>
          <div
            *ngIf="instagram"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="end end stretch"
            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/instagram.png"
              width="15"
              class="icon-c"
              alt="Instagram"
            />
            <p *ngIf="load" fxFlex class="icon-text">{{ instagram }}</p>
          </div>
          <div
            *ngIf="youtube"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="end end stretch"
            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/youtube_1.png"
              width="15"
              class="icon-c"
              alt="Youtube"
            />
            <p *ngIf="load" fxFlex class="icon-text">{{ youtube }}</p>
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between stretch"
          fxLayoutGap.gt-sm="20px"
          class="gray-box"
        >
          <p class="p-space academy">Horario</p>
        </div>
        <div class="pt-schedule pb-schedule">
          <ng-container *ngIf="schedule.length > 0">
            <div
              *ngFor="let item of schedule"
              fxLayout="row"
              fxLayout.lt-md="column"
              fxLayoutAlign="space-between stretch"
              fxLayoutGap.gt-sm="20px"
            >
              <p *ngIf="load" class="itemSchedule">
                <mat-icon class="icon-s">fiber_manual_record</mat-icon>
                {{ item.text }}
              </p>
            </div>
          </ng-container>
          <ng-container *ngIf="schedule.length === 0 && place.all_day">
            <p class="itemSchedule">
              <mat-icon class="icon-s">fiber_manual_record</mat-icon> Todo el
              dia
            </p>
          </ng-container>
          <ng-container *ngIf="schedule.length === 0 && !place.all_day">
            <p class="itemSchedule">
              <mat-icon class="icon-s">fiber_manual_record</mat-icon>
              {{ place.start_time_week }}-{{ place.end_time_week }}
            </p>
          </ng-container>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</mat-drawer-container>
