<mat-toolbar>
	<mat-toolbar-row>
		<div style="z-index: 9;" *ngIf="flag">
			<span class="material-icons icon"  (click)="onSidenavToggle()"> menu_open </span>
		</div>
		<div style="z-index: 9;" class="home" *ngIf="!flag">
			<a href="/dashboard"><img  width="40%" src="/assets/images/logo.svg"></a>
		</div>
		<div class="right-menu" fxFlex xLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
			<p><mat-icon class="white-icon icon bg-orange" [matBadge]="total" [matMenuTriggerFor]="menuBell">notifications</mat-icon></p>
			<p><mat-icon class="white-icon icon bg-yellow" matBadge="{{messages.length}}" [matMenuTriggerFor]="menuNotification">question_answer</mat-icon></p>
			<p><span class="material-icons icon" [matMenuTriggerFor]="menuHelp">help</span></p>
			<img class="img-profile" src="/assets/images/default.png" [matMenuTriggerFor]="menu">

			<mat-menu #menu="matMenu">
				<button class="font" mat-menu-item routerLink="/dashboard/basic">
				  <mat-icon>person_outline</mat-icon>
				  <span style="text-transform: capitalize;">{{"profiles.data-basic"|translate}}</span>
				</button>
				<button class="font" mat-menu-item routerLink="/dashboard/config">
				  <mat-icon>settings</mat-icon>
				  <span style="text-transform: capitalize;">{{"profiles.config"|translate}}</span>
				</button>
				<button class="font" mat-menu-item routerLink="/dashboard/payments">
				  <mat-icon>payment</mat-icon>
				  <span style="text-transform: capitalize;">{{"profiles.payment"|translate}}</span>
				</button>
				<mat-divider></mat-divider>
				<button class="font-2" mat-menu-item (click)="signOut()">
				  <span style="text-transform: capitalize;">{{"profiles.close"|translate}}</span>
				  </button>
			  </mat-menu>

			  <mat-menu #menuHelp="matMenu">
				<button class="font" mat-menu-item routerLink="/dashboard/comment">
				  <mat-icon>chat_bubble_outline</mat-icon>
				  <span>{{"help.comments"|translate}}</span>
				</button>
				<button class="font" mat-menu-item routerLink="/dashboard/help">
				  <mat-icon>support_agent</mat-icon>
				  <span> {{"help.help"|translate}}</span>
				</button>
				<mat-divider></mat-divider>
				<button class="font-2" mat-menu-item>
				  <span>{{"help.law"|translate}}</span>
				</button>
			  </mat-menu>

			<mat-menu #menuNotification="matMenu" class="mat-menu-panel-2" >
				<div fxLayout="column" fxLayoutAlign="space-between center">
					<div fxLayout="column" style="padding: 0 30px; width: 100%;">
						<div style="border-bottom: 1px solid #000; width:100%;">
							<p class="padding-message" translate>menu-header.title</p>
						</div>
						<div fxLayout="row" ngxSplit="row" *ngFor="let message of messages">
							<div fxLayout="row" ngxSplit="row" style="border-bottom: 1px solid #000; padding: 8px; width: 100%;" (click)="OpenChat(message.meeting_id)">
								<div fxFlex="15%" ngxSplitArea fxLayoutAlign="center center">
									<div fxLayout="row" ngxSplit="row">
										<div fxFlex="100%" ngxSplitArea class="the-box" >
											<img [src]="message.avatar" width="43px" alt="">
											<span matBadge="." matBadgeOverlap="false" ></span>
										</div>
									</div>
								</div>
								<div fxFlex="75%" ngxSplitArea style="padding: 0px 20px;">
									<div fxLayout="row" ngxSplit="row">
										<div fxFlex="100%" ngxSplitArea>
											<strong class="font-3">{{message.fullname}}</strong>
										</div>
									</div>
									<div fxLayout="row" ngxSplit="row">
										<div fxFlex="100%" ngxSplitArea>
											<strong class="font-3">{{message.subject}}</strong>
										</div>
									</div>
									<div fxLayout="row" ngxSplit="row">
										<div fxFlex="100%" ngxSplitArea>
											<p class="font-3">{{message.message}}</p>
										</div>
									</div>
								</div>
								<div fxFlex="10%" ngxSplitArea fxLayoutAlign="center end">
									<div fxLayout="row" ngxSplit="row" class="font-4">
										{{ message.createdAt | date:'yyyy-MM-dd HH:mm' }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div fxLayout="row" ngxSplit="row" style="width:100%; background:#FFCD29; margin-top: 8px;">
						<a href="dashboard/chat" class="footer-menu">{{"menu-header.show"|translate}}</a>
					</div>
				</div>
			</mat-menu>

			  <mat-menu #menuBell="matMenu" class="mat-menu-panel-2">
				<div fxLayout="column" fxLayoutAlign="space-between center">
					<div fxLayout="column" style="padding: 0; width: 100%;">
						<div style="border-bottom: 1px solid #000; width:100%;">
							<p class="padding-message" translate style="margin-left: 30px;">notifications.title</p>
						</div>
						<div class="not-dash-bc" *ngFor="let messagex of notifications; let i=index;" (click)="openNotificacion(i)">
							<div fxLayout="row" ngxSplit="row" class="not-dash">	
								<div fxFlex="15%" ngxSplitArea fxLayoutAlign="center start" style="padding-top: 10px;" >
									<div fxLayout="row" ngxSplit="row">
										<div fxFlex="100%" ngxSplitArea class="the-box-2" >
											<span matBadge="." matBadgeOverlap="false" *ngIf="messagex.status==0"></span>
											<mat-icon>{{messagex.image}}</mat-icon>
										</div>
									</div>
								</div>
								<div fxFlex="75%" ngxSplitArea fxLayoutAlign="center center" style="padding: 0px 3px; margin: 12px 5px 21px 15px;">
									<div fxLayout="row" ngxSplit="row">
										<div fxFlex="100%" ngxSplitArea>
											<!-- <strong class="font-3">{{message.name}}</strong> -->
											<p class="font-3"  [innerHTML]="messagex.title"></p>
										</div>
									</div>
								</div>
								<!--div fxFlex="10%" ngxSplitArea fxLayoutAlign="center end">
									<div fxLayout="row" ngxSplit="row" class="font-4">
										{{messagex.timer}}
									</div>
								</div-->
							</div>
							<div fxLayout="row" ngxSplit="row" style="border-bottom: 1px solid #000; padding: 0 8px 8px; margin-top: -20px;">
								<div fxFlex="100%" ngxSplitArea fxLayoutAlign="center end">
									<div fxLayout="row" ngxSplit="row" class="font-4" style="width: 100%; text-align: right;">
										{{messagex.timer}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div fxLayout="row" ngxSplit="row" style="width:100%; background:#F88113; margin-top: 8px;">
						<a href="/dashboard/notifications" class="footer-menu-w">{{'notifications.show'|translate}}</a>
					</div>
				</div>
			  </mat-menu>
		</div>
	</mat-toolbar-row>
</mat-toolbar>
<mat-divider></mat-divider>