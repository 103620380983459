
<mat-form-field class="example-full-width">
    <mat-label>Buscar</mat-label>
    <input type="text" matInput placeholder="" [(ngModel)]="contentSearch" (input)="addInput(contentSearch)">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
<div class="row">

    <div class="col-md-12 col-xs-12 scroll" #contentBoxChat>
        <mat-card class="cards-user {{data.flag==true?'click':''}}" *ngFor="let data of datas; let i = index" (click)="showChat(data.meet.meeting_id, data.user)">
            <div class="row">
                <div class="col-md-3 col-xs-3">
                    <img width="100%" [src]="data.user.image">
                </div>
                <div class="col-md-7 col-xs-7">
                    <p class="title">{{data.user.fullname}}</p>
                    <!-- <p class="subtitle">{{data.data.message}}</p> -->
                    <p>
                        <span style="font-size: 20px;" class="material-icons">star_rate</span>
                        <span style="font-size: 20px;" class="material-icons">star_rate</span>
                        <span style="font-size: 20px;" class="material-icons">star_rate</span>
                        <span style="font-size: 20px;" class="material-icons">star_rate</span>
                        <span style="font-size: 20px;" class="material-icons">star_rate</span>
                    </p>
                    <!--<p class="subtitle">{{data.course}}</p>-->
                </div>
                <div  *ngFor="let item of NotificationNew">
            
                    <div *ngIf="item.status && item.meeting_id == data.meet.meeting_id">
                        <span style="display: flex; top: 1rem;" matBadge="." ></span>
                    </div>
                    
                </div>
         
          
                  
                <div class="col-md-2 col-xs-2 centered">
                    <span matBadge="{{data.status}}" matBadgePosition="after"></span>
                </div>
            </div>
        </mat-card>
    </div>
</div>