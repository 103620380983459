<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
    <div class="content">
        <app-header></app-header>
        <app-sub-header *ngIf="load" [screen]="1"></app-sub-header>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutGap="40px" class="containerCourseDetails">
            <div fxFlex="65%" fxLayout="column" fxLayoutAlign="start start stretch">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="">
                    <p *ngIf="load" fxFlex class="title">{{courses.learn}}</p>
                </div>


                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="">
                    <p *ngIf="load" fxFlex class="subtitle">{{courses.category}}</p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="">
                    <p *ngIf="load" fxFlex class="description">{{courses.description}}</p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <mat-chip-list aria-label="Fish selection" class="space-image">
                        <mat-chip *ngFor="let item of tags">{{item}}</mat-chip>
                    </mat-chip-list>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" style="width: 100%;">
                    <div class="row" style="width: 100%;">
                        <div class="col-md-12 col-xs-12">
                            <app-slide-course-details [images]="imagesCarousel"></app-slide-course-details>
                        </div>
                    </div>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end" fxLayoutGap.gt-sm="10px" class="sectionSocial">
                    <p class="space" fxFlex>Compartir</p>
                    <img class="pointer" href="#" src="/assets/images/facebook_copia.png" width="25px" alt="Facebook">
                    <img class="pointer" href="#" src="/assets/images/twitter_copia_2.png" width="25px" alt="Twitter">
                    <img class="pointer" href="#" src="/assets/images/link_copia.png" width="25px" alt="Enlace">
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="width: 100%;">
                    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
                        <mat-tab label="Contenido">
                            <div *ngIf="load" class="long-tabs">
                                {{courses.content}}
                            </div>
                        </mat-tab>
                        <mat-tab label="Video">
                            <div class="long-tabs">
                                <youtube-player *ngIf="afterViewInit" [videoId]="videoId" suggestedQuality="highres" width="100%" height="500px">
                                </youtube-player>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
            <div fxFlex="35%" fxLayout="column" fxLayoutAlign="start start stretch" class="sidebarRight">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="box-discount">
                    <p *ngIf="load&&courses.discount!=null" class="p-space prices-orange" fxFlex>
                        {{courses.discount}}% Dto. $ {{courses.price}} {{courses.currency}}
                    </p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" class="gray-box" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <p *ngIf="load" class="p-space prices-big" fxFlex>
                        $ {{courses.discount!=null?courses.discounted_price:courses.price}} {{courses.currency}}
                    </p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="center-white">
                    <button mat-raised-button fxFlex (click)="openDialog()" class="example-icon favorite-icon style-login registrate asd" style="margin: 0px 20px;">
                            Inscribete en este curso
                        </button>
                    <a fxFlex routerLink="/cart" mat-button class="example-icon favorite-icon style-login registrate2 add-cart" style="margin: 0px 20px;" matBadge="1" matBadgePosition="after" matBadgeColor="warn">
                        <mat-icon>add_shopping_cart</mat-icon>
                    </a>
                    <a fxFlex routerLink="/cart" mat-button class="example-icon favorite-icon style-login registrate2" style="margin: 0px 20px;">
                        <mat-icon>favorite_border</mat-icon>
                    </a>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center" fxLayoutGap.gt-sm="20px" style="height: 31px;">
                    <span class="date">Fecha de Inicio: </span><span class="date-bold" *ngIf="load">{{format_data(courses.start_date)}}</span>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <span class="date">Edad: </span><span class="date-bold" *ngIf="load">{{courses.age}}</span>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <span class="date">Nivel: </span><span *ngIf="load" class="date-bold">{{courses.level}}</span>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="padding-bottom: 30px;">
                    <span class="date">Evaluado: </span><span *ngIf="load" class="date-bold">{{courses.approved ? 'Si': 'No'}}</span>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="gray-box">
                    <p *ngIf="load" class="p-space academy">Acerca del Curso</p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="40px" class="sideUser">
                    <div fxFlex="20%" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="userAvatar">
                            <img *ngIf="load" src="/assets/images/default.png" width="100%" alt="">
                        </div>
                    </div>
                    <div fxFlex="80%" fxLayout="column" fxLayoutAlign="center start stretch" class="width-full-side">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                            <p *ngIf="load" fxFlex class="address-user">Responsable:</p>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                            <p *ngIf="load" fxFlex class="name-user">{{course_teachers.name}} {{course_teachers.last_name}}</p>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                            <p *ngIf="load" fxFlex class="address-user">{{course_teachers.ubication}}</p>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                            <div fxFlex="80%" fxLayout="column" fxLayoutAlign="end center">

                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                                    <span *ngIf="load" fxFlex [ngSwitch]="star" class="start-rigth">
                                            <span class="star" *ngSwitchCase="0"><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="1"><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="2"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="3"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="4"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="5"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
                                    </span>
                                </div>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                                    <a class="start-rigth width-full-side link-start" *ngIf="load" href="/detail/teacher-public/{{course_teachers.id}}">Ver Calificaciones</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="40px" class="sideUser" style="margin-top: 0;">
                    <div fxFlex="20%" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="userAvatar">
                            <img *ngIf="load" src="/assets/images/default.png" width="100%" alt="">
                        </div>
                    </div>
                    <div fxFlex="80%" fxLayout="column" fxLayoutAlign="center start stretch" class="width-full-side">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                            <p *ngIf="load" fxFlex class="address-user">Lugar:</p>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                            <p *ngIf="load" fxFlex class="name-user">{{courses.place}}</p>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                            <p *ngIf="load" fxFlex class="address-user">San Cristobal, estado Tachira</p>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                            <div fxFlex="80%" fxLayout="column" fxLayoutAlign="end center">

                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                                    <span *ngIf="load" fxFlex [ngSwitch]="star" class="start-rigth">
                                            <span class="star" *ngSwitchCase="0"><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="1"><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="2"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="3"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="4"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="5"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
                                    </span>
                                </div>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                                    <p class="start-rigth width-full-side link-start">Ver Calificaciones</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="gray-box">
                    <p class="p-space academy">Acerca del Profesor</p>
                </div>
                <!-- Profesores--->
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="40px" class="sideUser">
                    <div fxFlex="20%" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="userAvatar">
                            <img *ngIf="load" src="/assets/images/default.png" width="100%" alt="">
                        </div>
                    </div>
                    <div fxFlex="80%" fxLayout="column" fxLayoutAlign="center start stretch" class="width-full-side">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                            <p *ngIf="load" fxFlex class="address-user">Responsable:</p>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                            <p *ngIf="load" fxFlex class="name-user">{{course_teachers.name}} {{course_teachers.last_name}}</p>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                            <p *ngIf="load" fxFlex class="address-user">{{course_teachers.ubication}}</p>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                            <div fxFlex="80%" fxLayout="column" fxLayoutAlign="end center">

                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                                    <span *ngIf="load" fxFlex [ngSwitch]="star" class="start-rigth">
                                            <span class="star" *ngSwitchCase="0"><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="1"><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="2"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="3"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="4"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon></span>
                                    <span class="star" *ngSwitchCase="5"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
                                    </span>
                                </div>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="width-full-side">
                                    <a class="start-rigth width-full-side link-start" *ngIf="load" href="/detail/teacher-public/{{course_teachers.id}}">Ver Calificaciones</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin de Profesores -->
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="gray-box">
                    <p class="p-space academy">Horario</p>
                </div>
                <div class="pt-schedule pb-schedule">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                        <p *ngIf="load && schedule" class="itemSchedule">
                            <mat-icon class="icon-s">fiber_manual_record</mat-icon> Fecha de Inicio {{schedule.start_date | date:'dd/MM/yyyy'}}: {{schedule.start_time[0]}} - {{schedule.start_time[1]}}</p>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                        <p *ngIf="load && schedule" class="itemSchedule">
                            <mat-icon class="icon-s">fiber_manual_record</mat-icon> Fecha de Fin {{schedule.end_date | date:'dd/MM/yyyy'}}: {{schedule.end_time[0]}} - {{schedule.end_time[1]}}</p>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                        <p *ngIf="load && !schedule" class="itemSchedule">
                            <mat-icon class="icon-s">fiber_manual_record</mat-icon> No Disponible</p>
                    </div>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</mat-drawer-container>