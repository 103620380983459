<div fxLayoutGap.lt-sm="3%" fxLayout.lt-sm="column" fxLayout.lt-md="row" fxLayoutGap.lt-lg="30px">
<div fxFlex="40%" fxFlex.lt-md="50%" fxLayout="column" fxLayoutAlign="space-between stretch" class="item-search">
  <div
    *ngIf="data.main_image != null"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between stretch"
    fxLayoutGap.gt-sm="20px"
    class="img-search"
    style="background-size: cover"
  >
    <img class="img-list" src="{{ data.main_image }}" alt="" />
  </div>
</div>

<div  fxFlex.lt-md="100%" fxFlex="60%" class="contenido" fxLayout="column" fxLayout.gt-md="row">
  <div class="info">
    
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between stretch"
      style="margin-bottom: 1rem"
    >
      <p class="style-categories">{{ data.category }}</p>
    </div>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between stretch"
    >
      <p class="style-place"><strong class="StrongPlace">Lugar:</strong> <br *ngIf="isMobile"> {{ data.address }}</p>
    </div>
    <div *ngIf="isMobile || isTablet" class="ver" style="margin: 5.3% 0;">
      <div>
        <button
          *ngIf="searching == 'cursos'"
          mat-raised-button
          fxFlex
          class="show-course-i"
          routerLink="/course/{{ data.id }}-{{ data.seo }}"
        >
          inscribirse ahora 
        </button>
        <button
          *ngIf="searching != 'cursos'"
          mat-raised-button
          fxFlex
          class="show-course"
          [routerLink]="
            searching == 'lugares' || searching == 'places'
              ? '/place/' + data.id + '-' + data.seo
              : '/event/' + data.id + '-' + data.seo
          "
        >
          ver
        </button>
      </div>
    </div>
    <div *ngIf="!isTablet">
      <p class="style-description">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Saepe nostrum dicta, minima tempore vel tempora ratione repudiandae dignissimos quae eius nihil suscipit quaerat amet labore deleniti quasi, optio voluptatibus sed!</p>
      <div
        *ngIf="data.teacher != null"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between stretch"
      >
        <p class="style-place">
          <strong>Instructor:</strong> {{ data.teacher }}
        </p>
      </div>
      <div
        fxLayout="row"
        *ngIf="data.level != null"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between stretch"
      >
        <p fxFlex class="style-description moved">
          <strong>Nivel:</strong> {{ data.level
          }}<span class="spaces">|</span>{{ data.aimed_to
          }}{{ data.evaluate }}
        </p>
      </div>
      <div 
      *ngIf="
        searching == 'cursos' ||
        searching == 'eventos' ||
        searching == 'events' && isMobile
      "
      class="start-date"
    >
    <p *ngIf="isMobile"> Fecha de inicio:  {{ data.start_date }}</p>
     
    </div>
  
      <div
        fxLayout="row"
        *ngIf="searching == 'eventos' || searching == 'events'"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between stretch"
      >
        <p fxFlex class="style-description moved">
          {{ data.place }}
          <span *ngIf="data.capacity">
            <span class="spaces">|</span>
            <strong>Capacidad :</strong> {{ data.capacity }}
          </span>
          <span class="spaces">|</span>
          <strong>Disponibilidad:</strong> {{ data.availability }}
        </p>
      </div>
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between stretch"
        style="margin-top: 1rem"
      >
        <p class="style-description">{{ data.description }}</p>
      </div>
    </div>
 
  </div>
</div>
</div>

  <div *ngIf="isTablet">
    <p class="style-description">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Saepe nostrum dicta, minima tempore vel tempora ratione repudiandae dignissimos quae eius nihil suscipit quaerat amet labore deleniti quasi, optio voluptatibus sed!</p>
    <div
      *ngIf="data.teacher != null"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between stretch"
    >
      <p class="style-place">
        <strong>Instructor:</strong> {{ data.teacher }}
      </p>
    </div>
    <div
      fxLayout="row"
      *ngIf="data.level != null"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between stretch"
    >
      <p fxFlex class="style-description moved">
        <strong>Nivel:</strong> {{ data.level
        }}<span class="spaces">|</span>{{ data.aimed_to
        }}{{ data.evaluate }}
      </p>
    </div>
    <div 
    *ngIf="
      searching == 'cursos' ||
      searching == 'eventos' ||
      searching == 'events' && isMobile
    "
    class="start-date"
  >
  <p *ngIf="isMobile"> Fecha de inicio:  {{ data.start_date }}</p>
   
  </div>
  
    <div
      fxLayout="row"
      *ngIf="searching == 'eventos' || searching == 'events'"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between stretch"
    >
      <p fxFlex class="style-description moved">
        {{ data.place }}
        <span *ngIf="data.capacity">
          <span class="spaces">|</span>
          <strong>Capacidad :</strong> {{ data.capacity }}
        </span>
        <span class="spaces">|</span>
        <strong>Disponibilidad:</strong> {{ data.availability }}
      </p>
    </div>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between stretch"
      style="margin-top: 1rem"
    >
      <p class="style-description">{{ data.description }}</p>
    </div>
  </div>
  <div *ngIf="!isMobile && !isTablet" class="ver">
    <div
      *ngIf="
        searching == 'cursos' ||
        searching == 'eventos' ||
        searching == 'events'
      "
      class="start-date"
    >
      Fecha de inicio:  {{ data.start_date }}
    </div>
    <div>
      <button
        *ngIf="searching == 'cursos'"
        mat-raised-button
        fxFlex
        class="show-course-i"
        routerLink="/course/{{ data.id }}-{{ data.seo }}"
      >
        inscribirse ahora 
      </button>
      <button
        *ngIf="searching != 'cursos'"
        mat-raised-button
        fxFlex
        class="show-course"
        [routerLink]="
          searching == 'lugares' || searching == 'places'
            ? '/place/' + data.id + '-' + data.seo
            : '/event/' + data.id + '-' + data.seo
        "
      >
        ver
      </button>
    </div>
  </div>

