import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private noti =new BehaviorSubject<any[]>([])
  noti$= this.noti.asObservable();
  baseurl: any;
  temp: string;
  MessagesNotifications : number;
  private messages: any[] = [];

  constructor(private __http: HttpClient,  private http: HttpClient) {
    this.MessagesNotifications = 0;
    this.baseurl = environment.url_server
  }

  getMessages(){
    return this.messages;
    
  }
  AddMessage(data){
    this.messages.push(data)
  }
  SeenMessage(id){
    const index = this.messages.findIndex((element) => element.meeting_id == id);
    if (index !== -1) {
      this.messages.splice(index, 1);
    }
  }

  post_notificationsgetNotificationsByUserID(data):Observable<any> {
    this.temp = this.baseurl + 'notifications/getNotificationsByUserID' ;
    // console.log("--------------data:", data);

    return this.__http.post( this.temp, data )
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  post_getActiveNotificationsByUserID(data):Observable<any> {
    this.temp = this.baseurl + 'notifications/getActiveNotificationsByUserID' ;
    return this.__http.post( this.temp, data )
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  put_notifications_status(data):Observable<any> {
    this.temp = this.baseurl + 'notifications/status' ;
    // console.log("--------------data:", data);
    return this.__http.put( this.temp, data )
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }

}
