import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CourseWebsiteService } from 'src/app/services/course-website.service';
import { environment } from "../../../environments/environment";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
}

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})

export class CourseDetailsComponent implements OnInit {
  start=5
  storage=environment.storage;
  load=false
  id: string;
  courses: any;
  imagesCarousel: string[]= [];
  urlApi:string ;
  tags: any;
  user: any;
  course_teachers: any;

  schedule: any;
  url=[
    {
      url:"/home",
      urlName:"Home/"
    },
    {
      url:"",
      urlName:"Categoria/"
    },
    {
      url:"",
      urlName:""
    }
  ]
  star=3;
  afterViewInit:boolean = false;
  videoId:string;

  constructor(
    private courseWebsiteService:CourseWebsiteService,
    private _route: ActivatedRoute,
    public dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    if (this.id) {
      let aux = this.id.split("-");
      this.id = aux[0];
      this.courseWebsiteService.get_courseDetail(this.id).subscribe(resp => {
        this.courses = resp.courses[0];
        this.course_teachers = resp.course_teachers[0];
        this.tags = this.courses.tags;
        this.user = resp.user[0];
        this.schedule = resp.schedule[0];
    console.log(this.course_teachers);

        if (this.tags) {
          var aux = this.tags.split(",");
          this.tags = aux;
        }
        this.load = true;
        this.url[2].urlName = this.courses.learn;
        this.urlApi = environment.storageServe;
        this.imagesCarousel = JSON.parse(this.courses.images);
        this.imagesCarousel = this.imagesCarousel.map(image => this.urlApi + '/' + image);
    
        if (this.courses.url) {
          const url = this.courses.url.split("v=");
          this.videoId = url[1];
        }
      });
    }
  }
  
  
  

  tagsArray(stringTags) {
    if(stringTags) {
      return stringTags.split(',');
    }
    return [];
  }

  format_data (date) {
    if(typeof date == 'string') {
      const arrayDate = date.split("-");
      return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
    }
    return date;
  }

  onTabChanged($event) {
    const {tab: {textLabel}} = $event;
    if(textLabel == 'Video') {
      this.afterViewInit = true;
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogCourse, { width: '80%' });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

@Component({
  selector: 'dialog-course',
  templateUrl: './dialog-course.html',
  styleUrls: ['./dialog-course.scss']
})
export class DialogCourse {
  constructor(
    public dialogRef: MatDialogRef<DialogCourse>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}