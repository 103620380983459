<div style="margin: 0 2rem">
  <div mat-dialog-title>
    <h2 class="m-title">Inscripción del Curso</h2>
  </div>
  <div mat-dialog-content>
    <div style="display: flex;">
      <div class="m-image"></div>
      <div class="m-content" style="width: 70%">
        <p>¿Estas seguro de que quieres Incribirte en este curso?</p>
        <div style="display: flex;">
          <div class="info-course">
            <h1>Adobe Photoshop</h1>
            <h2>Idiomas</h2>
          </div>
          <div class="price-course">
            <p class="p-space prices-big" fxFlex>
              CO$ 70.000
            </p>
          </div>
        </div>
        <p>¿Quien va a tomar este curso?</p>
        <select class="select-af">
          <option value="" selected disabled>Seleccione</option>
          <option value="1">Option 1</option>
          <option value="2">Option 2</option>
        </select>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end" class="content-actions">
    <button mat-button mat-dialog-close class="ingresar">Cancelar</button>
    <button mat-button mat-dialog-close cdkFocusInitial class="registrate">Aceptar</button>
  </div>
</div>