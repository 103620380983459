import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
//import { StripeService, StripeCardComponent } from 'ngx-stripe';
// import {
//   StripeCardElementOptions,
//   StripeElementsOptions
// } from '@stripe/stripe-js';
import { FormGroup, FormBuilder } from "@angular/forms";
import { HttpClient , HttpHeaders } from '@angular/common/http';

import { AddressService } from '../../../../../services/address.service';
import { CardService } from '../../../../../services/card.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-form-payment',
  templateUrl: './form-payment.component.html',
  styleUrls: ['./form-payment.component.scss']
})
export class FormPaymentComponent implements OnInit {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  myControl = new FormControl();
  options: string[];
  filteredOptions: Observable<string[]>;
  matcher = new MyErrorStateMatcher();
  credit:boolean=false;
  paypal:boolean=false;
  stripe:boolean=false;
  addScript = false;
  paypalConfig;
  processing;
  isPayed;
	paymentMsg;
  userData
  flag=false
  address
  cvc
  expired
  cardNumber
  cardName
  email
  emails

  //@ViewChild(StripeCardComponent) card: any;

  cardOptions: any = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: any = {
    locale: 'en'
  };

  stripeTest: FormGroup;

  @Output() form= new EventEmitter()
  constructor(
    private AddressService:AddressService,
    private CardService:CardService,
    private fb: FormBuilder,
  //private stripeService: StripeService,
    private http: HttpClient
  ) { }
  save(){
    console.log(this.emails)
    this.userData=JSON.parse(localStorage.getItem('userData'))
    // console.log(this.email)
    // let date=this.expired.split("-");
    // date=date[1]+"/"+date[0];
    // console.log(date)
    let data=[
      {
        card : [
          {
            type: 0,
            provider: "Paypal",
            email: this.emails,
            user_id: this.userData.id
          }
        ]
      }
    ]
    this.CardService.post_admincards(data).subscribe(resp=>{
      console.log(resp)
      if(resp.message_return.ERROR==false){
        this.form.emit(1)
      }
    });
  }
  ngOnInit(): void {

    this.stripeTest = this.fb.group({
      name: ['Angular v10', [Validators.required]],
      amount: [1001, [Validators.required, Validators.pattern(/\d+/)]],
    });

    this.userData=JSON.parse(localStorage.getItem('userData'));

    let data=[]
    this.AddressService.get_addresses(this.userData.id).subscribe(
      resz => {
        this.flag=true
        console.log(resz.address)
        if(resz.message_return.ERROR == false ){
          resz.address.forEach(element => {
            console.log(element)
            data.push({
              address:element.address+" "+element.country+" "+element.state+" "+element.city+" "+element.municipality+" "+element.parish,
              id:element.address_id
            })
            this.options=data
            this.flag=false
          })
        }
     }
    );

    this.paypalConfig = {
      env: 'sandbox', //environment.paypalEnv,
      style: {
        label: "checkout",
        size: "large",
        shape: "pill",
        color: "silver"
      },
      funding: {
        disallowed: [ "credit" ]
      },
      client: {
        sandbox: 'Af8yF3-RhIVZdEPl7s2ACUG6rb5wThaeed--w14oH8Knf4H46umIGQSFFXrhv03FX7nLsETR3qutzQE6',
        production: '',
      },
      commit: true,
      payment: (data, actions) => {
        return actions.payment.create({
          payment: {
            transactions: [
              {
                amount: {
                  total: '50',
                  currency: "USD",
                  details: {
                    subtotal: '50',
                    tax: "0.00",
                    shipping: "0.00",
                    handling_fee: "0.00",
                    shipping_discount: "0.00",
                    insurance: "0.00"
                  }
                },
                description: "The payment transaction description.",
                //invoice_number: bgon,
                payment_options: {
                  allowed_payment_method: "INSTANT_FUNDING_SOURCE"
                },
                soft_descriptor: "ECHI5786786",
                item_list: {
                  items: [
                    {
                      name: 'item 1',
                      description: "",
                      quantity: 1,
                      price: 50,
                      tax: "0.00",
                      sku: "1",
                      currency: "USD"
                    }
                  ],
                  shipping_address: {
                    recipient_name: '',
                    line1: 'direccion 1',
                    line2: 'direccion 2',
                    city: 'Dugway',
                    country_code: 'US',
                    postal_code: '84022',
                    state: 'UT'
                  }
                }
              }
            ],
            note_to_payer: "BeGlobal",
            redirect_urls: {
              return_url: "https://example.com/return",
              cancel_url: "https://example.com/cancel"
            }
          }
        });
      },
      onAuthorize: (data, actions) => {
        return actions.payment.execute()
          .then(payment => {

            const email = localStorage.getItem("currentUserEmail");
            //const address2shipping = this.getFilterValue(this.$order.Address2);
            //const address2biliing = this.getFilterValue(this.data.address2);

            this.processing="./assets/images/loadingbar.gif";

            let transactionId = payment.transactions[0].related_resources[0].sale.id;

            let url = '';//environment.beGlobalApiUrl+`/api/monitor/setOrder`;
            let requestData =
              {
                //data del producto pagado
              };


          })
          .catch(err => {
            this.isPayed = false;
            localStorage.setItem("ispayed", "false");
            this.paymentMsg = "Payment could not be executed.";
            this.processing = null;
          });
      },

      onCancel: function(data, actions) {
        this.isPayed = false;
        localStorage.setItem("ispayed", "false");
        this.paymentMsg = "Payment canceled.";
        this.processing = null;
      },

      onError: function(data, actions) {
        this.isPayed = false;
        localStorage.setItem("ispayed", "false");
        this.paymentMsg = "An error has happened.";
        this.processing = null;
      }
    };
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  onBack(){
    this.form.emit(1)
  }
  formCreditCard(){
    this.credit=true;
    this.paypal=false;
    this.stripe=false;
  }
  formStripe(){
    this.credit=false;
    this.paypal=false;
    this.stripe=true;
  }
  formPaypal(){
    // this.credit=false;
    // this.paypal=true;
    this.addScript = true;
    return new Promise((resolve, reject) => {
      const scripttagElement = document.createElement("script");
      scripttagElement.src = "https://www.paypalobjects.com/api/checkout.js";
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    });
  }
  saveCard(){
    this.userData=JSON.parse(localStorage.getItem('userData'))
    console.log(this.email)
    let date=this.expired.split("-");
    date=date[1]+"/"+date[0];
    console.log(date)
    let data=[
      {
        card : [
          {
            type: 1,
            provider: "MasterCard",
            email: this.email,
            card_name: this.cardName,
            card_number: this.cardNumber,
            expiration_date: date,
            CVC: this.cvc,
            billing_address_id: this.address,
            user_id: this.userData.id
          }
        ]
      }
    ]
    this.CardService.post_admincards(data).subscribe(resp=>{
      console.log(resp)
      if(resp.message_return.ERROR==false){
        this.form.emit(1)
      }
    });
  }



  createToken() {
    const name = this.stripeTest.get('name').value;
    // this.stripeService
    //   .createToken(null, { name }) //null por this.card.element
    //   .subscribe(result => {
    //     if (result.token) {
    //       // Use the token to create a charge or a customer
    //       // https://stripe.com/docs/charges
    //       console.log(result.token.id);

    //       this.http.post<any>("http://localhost:8000/api/stripe",{ "token" : result.token.id })
    //       .subscribe(data => {
    //           console.log(data);
    //       });
    //     } else if (result.error) {
    //       // Error creating the token
    //       console.log(result.error.message);
    //     }
    //   });
  }
}
