import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgmCoreModule } from '@agm/core';


/* ngx-translate */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

/* Modules */
import { MaterialModule } from './material/material.module';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { MomentModule } from 'ngx-moment';

/* Components */
import { AppComponent } from './app.component';
// import { WebsiteModule } from './website/website.module';
import { WebsiteComponent } from "./website/website.component";
import { HeaderComponent } from "./website/components/header/header.component";
import { FooterComponent } from "./website/components/footer/footer.component";
import { IndexComponent } from "./website/index/index.component";
import { SearchingComponent } from "./website/searching/searching.component";
import { TemplateComponent } from "./website/searching/template/template.component";
import { RetryInterceptor } from './interceptors/retry.interceptor';

//import { NgxStripeModule } from 'ngx-stripe';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { FirstStepsComponent } from './website/first-steps/first-steps.component';
import { SubHeaderComponent } from './website/components/sub-header/sub-header.component';
import { NewsletterComponent } from "./website/components/newsletter/newsletter.component";
import { FrontSlidersComponent } from './website/components/front-sliders/front-sliders.component';
import { SectionLeftComponent } from './website/components/section-left/section-left.component';
import { SectionRigthComponent } from './website/components/section-rigth/section-rigth.component';
import { AboutUsComponent } from './website/about-us/about-us.component';
import { TeacherFrontendComponent } from './website/teacher-frontend/teacher-frontend.component';
import { BoxesComponent } from './website/components/boxes/boxes.component';
import { MiniSectionLeftComponent } from './website/components/mini-section-left/mini-section-left.component';
import { MiniSectionRigthComponent } from './website/components/mini-section-rigth/mini-section-rigth.component';
import { PromotionsComponent } from './website/components/promotions/promotions.component';
import { BusinessFrontendComponent } from './website/business-frontend/business-frontend.component';
import { CartComponent } from './website/cart/cart.component';
import { TeachersComponent } from './website/searching/teachers/teachers.component';
import { CourseDetailsComponent } from './website/course-details/course-details.component';
import { ContactComponent } from './website/contact/contact.component';
import { TermConditionComponent } from './website/term-condition/term-condition.component';
import { CarouselWebsiteComponent } from './website/components/carousel-website/carousel-website.component';
import { SwiperModule, SwiperConfigInterface,SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { PaymentProccessComponent } from './website/payment-proccess/payment-proccess.component';
import { HeaderPaymentComponent } from './website/payment-proccess/header-payment/header-payment.component';
import { BreadcrumbComponent } from './website/components/breadcrumb/breadcrumb.component';
import { SlideCourseDetailsComponent } from './website/components/slide-course-details/slide-course-details.component';
import { ConfirmBuyComponent } from './website/confirm-buy/confirm-buy.component';
import { PlaceDetailsComponent } from './website/place-details/place-details.component';
import { BioTeacherComponent } from './website/bio-teacher/bio-teacher.component';
import { EventDetailsComponent } from './website/event-details/event-details.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TokenInterceptor } from './interceptors/token.interceptor'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TestComponent } from './test/test.component';
import {ModalPostulationComponent} from './dashboard/views/teachers/components/modal-postulation/modal-postulation.component'
import { DetailsTeacherComponent} from './website/searching/teachers/details-teachers-public/details-teachers.component';
import { ItemSearchComponent } from './website/searching/item-search/item-search.component';
import { ModalContactMessageComponent } from './website/searching/teachers/modal-contact-message/modal-contact-message.component'

const config: SocketIoConfig = { url: environment.url_chatSocket, options: {} };
//const config: SocketIoConfig = { url: 'http://localhost:3000/', options: {} };


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};
@NgModule({
  declarations: [
    AppComponent,
    WebsiteComponent,HeaderComponent,
    FirstStepsComponent,
    FooterComponent,
    IndexComponent,
    SearchingComponent,
    NewsletterComponent,
    TemplateComponent,
    SubHeaderComponent,
    FrontSlidersComponent,
    SectionLeftComponent,
    SectionRigthComponent,
    AboutUsComponent,
    TeacherFrontendComponent,
    BoxesComponent,
    MiniSectionLeftComponent,
    MiniSectionRigthComponent,
    PromotionsComponent,
    BusinessFrontendComponent,
    CartComponent,
    TeachersComponent,
    CourseDetailsComponent,
    ContactComponent,
    TermConditionComponent,
    CarouselWebsiteComponent,
    PaymentProccessComponent,
    HeaderPaymentComponent,
    BreadcrumbComponent,
    SlideCourseDetailsComponent,
    ConfirmBuyComponent,
    PlaceDetailsComponent,
    BioTeacherComponent,
    EventDetailsComponent,
    TestComponent,
    ModalPostulationComponent,
    DetailsTeacherComponent,
    ItemSearchComponent,
    ModalContactMessageComponent
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    SwiperModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    }),
    BrowserAnimationsModule,
    MaterialModule,
    // WebsiteModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AuthModule,
    AppRoutingModule,
    DashboardModule,
    AgmCoreModule.forRoot({
      apiKey: environment.key_google_maps,
      language: "en",
		  libraries: ['places','geometry']
      // apiKey: 'AIzaSyCsXmdVJYbNNRHi8-9p3ePxh_SZ-Z5l8to'
    }),
   // NgxStripeModule.forRoot('pk_test_51H2K2SFhOwwCw4a8mGWFVVopUThGi5qDnJ3xTtxD3QswBnWqD1nqxchLnE66gqQZBwF3kTY2QafczybGgU1qDzd100ycQ6wPaq'),
    NgxYoutubePlayerModule.forRoot(),
    SocketIoModule.forRoot(config),
    MatDialogModule,
    MatIconModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
      //provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  //schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/language/', '.json');
}
