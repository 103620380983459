<mat-drawer-container
  class="drawer-container"
  style="overflow: hidden !important"
>
  <div class="content" ng>
    <app-header></app-header>
    <app-sub-header [screen]="1"></app-sub-header>

    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-between none"
      fxLayoutGap="40px"
      class="containerPlaceDetails"
      style="padding-right: 6rem"
    >
      <div class="perfil">
        <div
          fxLayout="row"
          fxLayout.lt-md="row"
          fxLayoutAlign="end end"
          fxLayoutGap.gt-sm="0.3em"
          fxLayoutGap.lt-md="0.3em"
          class="sectionSocial"
        >
          <p class="space" fxFlex>Compartir </p>
            <img  class="pointer" href="#" src="/assets/images/facebook_copia.png" width="25px" alt="Facebook">
           <img class="pointer" href="#" src="/assets/images/twitter_copia_2.png" width="25px" alt="Twitter">  
           <img class="pointer" href="#" src="/assets/images/link_copia.png" width="25px" alt="Enlace">  
        </div>

        <H1>Acerca de mi</H1>

        <div>       
      {{teacherData?.bio}}
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between stretch"
          fxLayoutGap.gt-sm="20px"
          style="width: 100%"
        >
          <mat-tab-group style="width: 100%">
            <mat-tab label="Cursos">
              <div *ngIf="coursesData?.courses">Cursos no disponibles</div>
              <div class="long-tabs" >
<div fxLayout.lt-md="column" fxLayout="row"    routerLink="/course/{{ curso.id }}-{{ curso.name }}"     fxLayoutAlign.lt-md="space-between center" class="containerlist" *ngFor="let curso of coursesData" style="width: 100%;">
<div class="image-cur" ><img [src]="baseUrl + '/' +curso.main_image "  alt="" class="imgcourse"></div>
<div class="title-cur">
  <div class="nombrecurso">{{curso.name}}</div>
  <div class="categorycur"><b>{{curso.category}}</b></div>
  <div> <b>Nivel:</b> {{curso.level}} | Para mayores de {{curso.age}} | <span *ngIf="curso.evaluate">Evaluado</span>  <span *ngIf="!curso.evaluate">No evaluado</span></div>
</div>
</div>



              </div>
            </mat-tab>

            <mat-tab label="Eventos"  >
              <div *ngIf="teacherData">Eventos no disponibles</div>
              <div class="long-tabs"><div class="containerlist" *ngFor="let event of teacherData?.events"  routerLink="/event{{ event.id }}-{{ event.name }}">
                <div class="image-cur" ><img src="{{event.main_image}}" alt="" class="imgcourse"></div>
                <div class="title-cur">
                  <div class="nombrecurso">{{event.name}}</div>
                  <div class="categorycur">{{event.category}}</div>
                  <div>{{event.level}} | Para mayores de {{event.age}} | <span *ngIf="event.availability">Evaluado</span>  <span *ngIf="!event.availability">No Evaluado</span></div>
                </div>
                </div></div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div
        class="contacto"
        class="sidebarRight"
      >
        <div class="imagen">

          <img [src]="baseUrl+'/'+teacherData.avatar" (error)="onImgError($event)"/>
        </div>

        <div class="nombre">
          <mat-icon style="margin-right: 1rem;">business_center_outlined</mat-icon>  <span style="margin-right: .5rem;"> {{teacherData?.user_name}}  </span>
        </div>
        <div class="profesion">
          {{teacherData?.title}} 
        </div>
        <div>
          <ul>
           <li *ngFor=" let item of teacherData?.skills"> {{item.name}}</li>
          </ul>
        </div>
        <div class="redes">
          <div
            fxLayout="row"
  
            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/facebook.png"
              width="27"
              class="icon-c"
              alt="facebook"
            />
            <span style=" margin: 0 1em;">  
            {{teacherData?.facebook_url}}
            </span>
          </div>
          <div
            fxLayout="row"
    
            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/twitter.png"
              width="27"
              class="icon-c"
              alt="Twitter"
            />
            <span style=" margin: 0 1em;">  
                {{teacherData?.twitter_url}}
            </span>
          </div>
          <div
            fxLayout="row"

            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/instagram.png"
              width="27"
              class="icon-c"
              alt="Instagram"

            />
            <span style=" margin: 0 1em;">  
              {{teacherData?.instagram_url}}
              </span>
      


          </div>
          <div
            fxLayout="row"

      
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/youtube_1.png"
              width="27"
              class="icon-c"
              alt="Youtube"
        
            />
            <span style=" margin: 0 1em;">         
                 {{teacherData?.youtube_url}}
            </span>

            
          </div>
        </div>

        <div class="enviar">
          <button   (click)="rejectproposalreceived()">CONTACTAR</button>
          <!-- <button>ENVIAR PROPUESTA</button> -->
        </div>

      </div>
    </div>
    <app-footer></app-footer>
  </div>
</mat-drawer-container>
