// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  public_key_recaptcha: "6LePLAweAAAAAOS6u7k3P42ZfElzfv_S9Kg3VeIg",
  url_chatSocket: "http://127.0.0.1:8000/",
  storage: 'http://dev.sogcial.com',
  url_server: 'https://dev.sogcial.com/api/',
  storageServe: 'https://dev.sogcial.com/',
  ipify_url: 'http://api.ipify.org/?format=json',
  key_google_maps: "AIzaSyBq3BL-vyLER2ILrUCzFKMslE20MmGxoPs"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
