import { Component, OnInit,ViewChild,Output,Input,EventEmitter, ElementRef, ChangeDetectorRef  } from '@angular/core';
import { Socket } from 'ngx-socket-io';

import { SocketIoService } from '../../../../services/socket-io.service';
import { BehaviorSubject, from } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { element } from 'protractor';
import { NotificationsService } from "../../../../services/notifications.service";

interface Notificacion  {
  meeting_id : Number | String,
  status  : Boolean,
}
@Component({
  selector: 'app-box-user',
  templateUrl: './box-user.component.html',
  styleUrls: ['./box-user.component.scss']
})

export class BoxUserComponent implements OnInit {
  NotificationNew : any[]= [{
    meeting_id: 0, status: 0
  }]
  // @ViewChild(DescriptionsComponent) descriptions;
  // @ViewChild(GalleryComponent) gallery;

  @ViewChild("contentBoxChat") contentBoxChatL: ElementRef;

  user:any = JSON.parse( localStorage.getItem("userData") );
  datas: any[] = []; 
  chat: any[] = []
  
  constructor(   private socket: SocketIoService , private cdRef: ChangeDetectorRef,    private NotificationsService:NotificationsService,) { }
  dataTypes: string[] = this.datas.map(data => typeof data.meet.meeting_id);

   chatDataSubscription;
   selectMeet;
   meetings;
   contentSearch: string;
   privateChats: any[] = [];
   originDatas :any[];
   SearchChat(search: string): void {
    if (search) {
      const searchLower = search.toLowerCase();
      this.datas = this.originDatas.filter(objeto => objeto.user.fullname.toLowerCase().includes(searchLower));
    } else {
      this.datas = this.originDatas;
    }

  }
  
  initMeet(data):void{
    const Select = localStorage.getItem("SelectMeeting_id")
    if(data){
      if(Select){
        this.selectMeet = Select;
        this.showChat( this.selectMeet ,data[0].user )
      }
      else{
        this.showChat( data[0].meet.meeting_id, data[0].user )
      }
    }
  
   }
  getMeetings(): void {
    const idUser = JSON.parse(localStorage.getItem("userData")).id;
  
    this.socket.getMeeting(idUser);
    let hasReceivedData = false;
  
    this.chatDataSubscription = this.socket.listenForChatsData().subscribe((privateChats) => {
      this.datas = privateChats;
      this.originDatas = this.datas;
      privateChats.forEach(item=> {
  
        if(item.new ===1){
          const notificacion: Notificacion = {meeting_id :item.meet.meeting_id, status :true}
          this.NotificationNew.push(notificacion);
        }
      })
      this.initMeet(privateChats)
      if (!hasReceivedData) {
     // this.NotificationLast();
        hasReceivedData = true; 
      }

  

    });
  }


   

  ngOnInit(): void {



    // this.chat = [
    //   {
    //     id: "1", 
    //     name: "jose",
    //     img : "imagen.jpg",
    //     estrrellas: 4,
    //     mensajes : [
    //       {
    //         message: "mensaje",
    //         meeting_id: "1",
    //         nombre: "armando",
    //         create_at: "yy/mm/dd",

    //     }
    //   ]
    //   },
    //   {
    //     id: "2", 
    //     name: "armando",
    //     img : "imagen.jpg",
    //     estrrellas: 4,
    //     mensajes : [
    //       {
    //         message: "mensaj de armande eeeee",
    //         meeting_id: "1",
    //         nombre: "jose",
    //         create_at: "yy/mm/dd",

    //     }
    //   ]
    //   }
    //  ];
     
    //  search(content:string){
      
    //  }
     
     
    this.getMeetings();
   
    

    this.socket.listenToServerEvents('sendMessagesPrivate').subscribe((data: any) => {

      const notificacion: Notificacion = { meeting_id: parseInt(data.meeting_id, 10), status: true };

      const meetingIdExists = this.NotificationNew.some((item) => item.meeting_id === notificacion.meeting_id && item.status === true);
  
      if (!meetingIdExists ) {

        if(data.user_id != JSON.parse( localStorage.getItem("userData") ).id){

          this.NotificationNew.push(notificacion);
        }
      } 

      this.cdRef.detectChanges();

    });

    
    
  }
  message: string = "Hola Mundo!"

  @Output() messageEvent = new EventEmitter<string>();
  @Output() name = new EventEmitter<string>();
  addInput(e:string) : void{
    this.SearchChat(e);
  }
  showChat(id, data){
    console.log(`abrinedo el chat el id es ${id}`)
    this.NotificationsService.SeenMessage(id)
    // this.NotificationsService.SeenNotification()

    this.NotificationNew.forEach((item, index) => {
      if (item.meeting_id == id) {
        this.NotificationNew.splice(index, 1);
      }
    });
    
    
    this.cdRef.detectChanges();

    localStorage.setItem("meetting_id_current" , id );
    const jsondata = {meeting_id: id, user_id: JSON.parse( localStorage.getItem("userData") ).id   };

    this.socket.JoinMeeting(jsondata)

    this.messageEvent.emit(id)
    this.name.emit(data.name)
  }
}
