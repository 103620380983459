<div *ngIf="!loadTeachers" fxLayoutAlign="center center" fxflex>
  <mat-spinner strokeWidth="10" [diameter]="24"></mat-spinner>
</div>
<div fxLayout="column" fxflex>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    style="padding: 0rem 5rem"
  >
    <!-- <mat-form-field fxFlex="100%">
      <mat-label>{{ "create-teacher.search" | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field> -->
  </div>

  <div fxLayout="column" fxLayoutGap="10px" style="margin: 36px 2px 2px 2px">
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <app-message
        *ngIf="msg != null"
        [msg]="msg"
        [typeMsg]="typeMsg"
      ></app-message>
    </div>
    <!-- <app-filter></app-filter> -->
    <div
      fxLayout="row"
      fxLayoutAlign="start none"
      fxLayoutGap="10px"
      style="padding: 0rem 5rem"
    >
      <mat-form-field fxFlex="100%">
        <mat-label>{{ "place.search" | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event)" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <button
        class="btn-filter"
        [ngClass]="{ 'border-orange': !hide }"
        (click)="hide = !hide"
      >
        <mat-icon
          class="icon-grey"
          style="margin-top: -8px"
          [ngClass]="{ 'color-orange': !hide }"
          >filter_list</mat-icon
        >
      </button>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
      *ngIf="!hide"
      style="padding: 0rem 5rem"
    >
      <p fxFlex="6%" class="paragraph">Filtrar por:</p>

      <mat-form-field fxFlex="30%">
        <mat-label>Titulo</mat-label>
        <mat-select #selectitle (selectionChange)="filterTitle(selectitle)">
          <mat-option *ngFor="let title of titles" [value]="title">
            {{ title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span><button class="reset" (click)="cleartitle(selectitle)" >X</button></span>

      <mat-form-field fxFlex="30%">
        <mat-label>{{ "place.city" | translate }}</mat-label>
        <mat-select #seleccity (selectionChange)="filterLocation(seleccity)">
          <mat-option *ngFor="let citys of city" [value]="citys">
            {{ citys }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span><button class="reset"  (click)="clearcity(seleccity)">X</button></span>

      <mat-form-field fxFlex="30%">
        <mat-label>{{ "place.state" | translate }}</mat-label>
        <mat-select #selecstat (selectionChange)="filterState(selecstat)">
          <mat-option *ngFor="let stats of states" [value]="stats">
            {{ stats }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span><button class="reset" (click)="clearstate(selecstat)">X</button></span>
    </div>

    <div fxLayout="column" fxLayoutAlign="center stretch">
      <app-message
        *ngIf="msg != null"
        [msg]="msg"
        [typeMsg]="typeMsg"
      ></app-message>
    </div>

    <div class="tabla">
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="image">
          <mat-header-cell fxFlex="20" *matHeaderCellDef></mat-header-cell>
          <mat-cell fxFlex="13" *matCellDef="let element">
            <div class="perfil">
              <div>
                <div>
                  <img
                    [src]="element.avatar"
                    class="img"
                    (error)="onImgError($event)"
                  />
                </div>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="body">
          <mat-header-cell fxFlex="60" *matHeaderCellDef></mat-header-cell>
          <mat-cell fxFlex="90" class="tab-td" *matCellDef="let element">
            <div class="calificacion">
              <div class="nombre">
                <div>
                  <p class="raleway-14-bold">
                    {{ element.name }} {{ element.last_name }}
                  </p>
                </div>
                <div>
                  <p class="oxigen-13">
                    {{ element.Ubication }}
                  </p>
                </div>

                <div>
                  <p class="raleway-14-bold">{{ element.title }}</p>
                </div>

                <div fxLayout="row">
                  <mat-icon
                    *ngFor="
                      let item of [].constructor(element.teacher_average_stars)
                    "
                    >star</mat-icon
                  >
                  <!-- <mat-icon>star_border</mat-icon> -->
                </div>
              </div>

              <div class="tags">
                <mat-chip-list>
                  <mat-chip *ngFor="let item of element.skills"
                    >{{ item }}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="invite">
          <mat-header-cell fxFlex="20" *matHeaderCellDef></mat-header-cell>

          <mat-cell
            fxFlex="20"
            *matCellDef="let element"
            style="display: flex; justify-content: end"
          >
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button
                [routerLink]="['/', 'dashboard', 'teacher', element.UserId]"
                mat-menu-item
              >
                <span>Ver</span>
              </button>
              <button mat-menu-item>
                <a href="mailto:{{ element.email }}">Contactar</a>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>

    <mat-paginator
      #paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
    ></mat-paginator>
    <div fxLayoutAlign="center center" fxFlex="30"></div>
  </div>
</div>
