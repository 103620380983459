<mat-card style="position: fixed; width: 100%; z-index: 10;">
    <mat-toolbar id="mat-toolbar" style="height: 52px;">
      <!-- button toggle -->
      <button id="toggle-button-header" mat-icon-button [matMenuTriggerFor]="menu" *ngIf="isMobile">
        <mat-icon class="mat-icon-menu">menu</mat-icon>
      </button>
  
      <!-- Logo -->
      <span  class="pointer logo-header" routerLink="/">
        <img id="logo" src="/assets/images/logo.png" alt="" srcset="" style="width: 130px;">
      </span>
  
      <!-- menu Desktop -->
      <span class="example-spacer"></span>
      <div class="menu" *ngIf="!isMobile">
        <p routerLink='/' mat-button class="example-icon favorite-icon style-menu pointer {{ screen == null ? 'active' : '' }}" >
          Inicio
        </p>
        <p routerLink='/search/lugares' mat-button class="example-icon favorite-icon style-menu pointer {{ screen == 'lugares' ? 'active' : '' }}" >
          Lugares
        </p>
        <p routerLink='/search/cursos' mat-button class="example-icon favorite-icon style-menu pointer {{ screen == 'cursos' ? 'active' : '' }}" >
          Cursos
        </p>
        <p routerLink='/search/eventos' mat-button class="example-icon favorite-icon style-menu pointer {{ screen == 'eventos' ? 'active' : '' }}" >
          Eventos
        </p>
        <p routerLink='/search/teachers' mat-button class="example-icon favorite-icon style-menu pointer {{ screen == 'teachers' ? 'active' : '' }}" >
          Teachers
        </p>
        <p routerLink="/contact" mat-button class="example-icon favorite-icon style-menu pointer {{ screen == 'contact' ? 'active' : '' }}" >
          Contacto
        </p>
      </div>
      <button *ngIf="!flag && isMobile == false" routerLink="/auth/login" mat-button class="example-icon favorite-icon style-login ingresar" >
        Ingresar
    </button>
    <button *ngIf=" !flag && isMobile == false" routerLink="/auth/register" mat-button class="example-icon favorite-icon style-login registrate" style="margin: 0px 20px;" >
        Registrate
    </button>
    <div *ngIf="flag" class="right-menu" fxFlex xLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
        <p ><mat-icon class="white-icon icon bg-orange" matBadge="{{total}}" [matMenuTriggerFor]="menuBell">notifications</mat-icon></p>
        <p *ngIf="isMobile == false"><mat-icon class="white-icon icon bg-yellow" matBadge="{{MessagesNotifications}}" [matMenuTriggerFor]="menuNotification">question_answer</mat-icon></p>
        <p *ngIf="isMobile == false"><mat-icon class="white-icon icon bg-blue" matBadge="0" routerLink="/cart">shopping_cart</mat-icon></p>
        <img class="img-profile" src="/assets/images/default.png" [matMenuTriggerFor]="menu">

        <mat-menu #menu="matMenu">
            <button class="font" mat-menu-item routerLink="/dashboard/basic">
              <mat-icon>person_outline</mat-icon>
              <span style="text-transform: capitalize;">{{"profiles.data-basic"|translate}}</span>
            </button>
            <button class="font" mat-menu-item routerLink="/dashboard/courses">
                <mat-icon>menu_book</mat-icon>
                <span style="text-transform: capitalize;">Mis Cursos</span>
              </button>
              <button class="font" mat-menu-item >
                <mat-icon>supervisor_account</mat-icon>
                <span style="text-transform: capitalize;">Mis afiliados</span>
              </button>
            <button class="font" mat-menu-item routerLink="/dashboard/config">
              <mat-icon>settings</mat-icon>
              <span style="text-transform: capitalize;">{{"profiles.config"|translate}}</span>
            </button>
            <button class="font" mat-menu-item routerLink="/dashboard/payments">
              <mat-icon>payment</mat-icon>
              <span style="text-transform: capitalize;">{{"profiles.payment"|translate}}</span>
            </button>
            <mat-divider></mat-divider>
            <button class="font-2" mat-menu-item (click)="signOut()">
              <span style="text-transform: capitalize;">{{"profiles.close"|translate}}</span>
              </button>
          </mat-menu>

          <mat-menu #menuHelp="matMenu">
            <button class="font" mat-menu-item routerLink="/dashboard/comment">
              <mat-icon>chat_bubble_outline</mat-icon>
              <span>{{"help.comments"|translate}}</span>
            </button>
            <button class="font" mat-menu-item routerLink="/dashboard/help">
              <mat-icon>support_agent</mat-icon>
              <span> {{"help.help"|translate}}</span>
            </button>
            <mat-divider></mat-divider>
            <button class="font-2" mat-menu-item>
              <span>{{"help.law"|translate}}</span>
            </button>
          </mat-menu>

        <mat-menu #menuNotification="matMenu" class="mat-menu-panel-2">
            <div fxLayout="column" fxLayoutAlign="space-between center">
                <div fxLayout="column" style="padding: 0 30px; width: 100%;">
                    <div style="border-bottom: 1px solid #000; width:100%;">
                        <p class="padding-message" translate>menu-header.title</p>
                    </div>
                    <div fxLayout="row" ngxSplit="row" *ngFor="let message of messages">
                        <div fxLayout="row" ngxSplit="row" style="border-bottom: 1px solid #000; padding: 8px; width: 100%;" (click)="OpenChat(message.meeting_id)">
                            <div fxFlex="15%" ngxSplitArea fxLayoutAlign="center center">
                                <div fxLayout="row" ngxSplit="row">
                                    <div fxFlex="100%" ngxSplitArea class="the-box" >
                                        <img [src]="message.avatar" width="43px" alt="">
                                        <span matBadge="." matBadgeOverlap="false" ></span>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="75%" ngxSplitArea style="padding: 0px 6px;">
                                <div fxLayout="row" ngxSplit="row">
                                    <div fxFlex="100%" ngxSplitArea>
                                        <strong class="font-3">{{message.fullname}}</strong>
                                    </div>
                                </div>
                                <div fxLayout="row" ngxSplit="row">
                                    <!-- <div fxFlex="100%" ngxSplitArea>
                                        <strong class="font-3">{{message.subject}}</strong>
                                    </div> -->
                                </div>
                                <div fxLayout="row" ngxSplit="row">
                                    <div fxFlex="100%" ngxSplitArea>
                                        <p class="font-3">{{message.message}}</p>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="10%" ngxSplitArea fxLayoutAlign="center end">
                                <div fxLayout="row" ngxSplit="row" class="font-4">
                                  {{ message.createdAt | date:'yyyy-MM-dd HH:mm' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" ngxSplit="row" style="width:100%; background:#FFCD29; margin-top: 8px;">
                    <a href="dashboard/chat" class="footer-menu" style="  color:#000;  font-size: 12px; margin: auto; font-weight: bold;   padding: 7px; text-decoration: none;">{{"menu-header.show"|translate}} </a>
                </div>
            </div>
        </mat-menu>

          <mat-menu #menuBell="matMenu" class="mat-menu-panel-2">
            <div fxLayout="column" fxLayoutAlign="space-between center">
                <div fxLayout="column" style="width: 100%;">
                    <div style="border-bottom: 1px solid #000; width:100%;">
                        <p class="padding-message" translate>notifications.title</p>
                    </div>
                    <div *ngFor="let messagex of notifications; let i=index;" class="not-h" (click)="openNotificacion(i)">
                        <div fxLayout="row" ngxSplit="row" style="padding: 8px 8px 0;">
                            <div fxFlex="15%" ngxSplitArea fxLayoutAlign="center start" style="padding-top: 10px;" >
                                <div fxLayout="row" ngxSplit="row">
                                    <div fxFlex="100%" ngxSplitArea class="the-box-2" >
                                        <span matBadge="." matBadgeOverlap="false" *ngIf="messagex.status==0"></span>
                                        <mat-icon>{{messagex.image}}</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="75%" ngxSplitArea fxLayoutAlign="center center" style="padding: 0px 3px; margin: 12px 5px 21px 15px;">
                                <div fxLayout="row" ngxSplit="row">
                                    <div fxFlex="100%" ngxSplitArea>
                                        <!-- <strong class="font-3">{{message.name}}</strong> -->
                                        <p class="font-3"  [innerHTML]="messagex.title"></p>
                                    </div>
                                </div>
                            </div>
                            <!--div fxFlex="10%" ngxSplitArea fxLayoutAlign="center end">
                                <div fxLayout="row" ngxSplit="row" class="font-4">
                                    {{messagex.timer}}
                                </div>
                            </div-->
                        </div>
                        <div fxLayout="row" ngxSplit="row" style="border-bottom: 1px solid #000; padding: 0 8px 8px; margin-top: -20px;">
                            <div fxFlex="100%" ngxSplitArea fxLayoutAlign="center end">
                                <div fxLayout="row" ngxSplit="row" class="font-4">
                                    {{messagex.timer}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" ngxSplit="row" style="width:100%; background:#F88113;">
                    <a href="/dashboard/notifications" class="footer-menu-w">{{'notifications.show'|translate}}</a>
                </div>
            </div>
          </mat-menu>
    </div>
  
  
      <!-- Mobile menu -->
      <mat-menu #menu="matMenu" class="mobile-menu">
        <button mat-menu-item routerLink='/' class="example-icon favorite-icon style-menu pointer {{ screen == null ? 'active' : '' }}" >
          Inicio
        </button>
        <button mat-menu-item routerLink='/search/lugares' class="example-icon favorite-icon style-menu pointer {{ screen == 'lugares' ? 'active' : '' }}" >
          Lugares
        </button>
        <button mat-menu-item routerLink='/search/cursos' class="example-icon favorite-icon style-menu pointer {{ screen == 'cursos' ? 'active' : '' }}" >
          Cursos
        </button>
        <button mat-menu-item routerLink='/search/eventos' class="example-icon favorite-icon style-menu pointer {{ screen == 'eventos' ? 'active' : '' }}" >
          Eventos
        </button>
        <button mat-menu-item routerLink='/search/teachers' class="example-icon favorite-icon style-menu pointer {{ screen == 'teachers' ? 'active' : '' }}" >
          Teachers
        </button>
        <button mat-menu-item routerLink="/contact" class="example-icon favorite-icon style-menu pointer {{ screen == 'contact' ? 'active' : '' }}" >
          Contacto
        </button>
        
        <button *ngIf="!flag" routerLink="/auth/login" mat-button class=" ingresar" style="margin: 10px" >
          Ingresar
      </button>
      <button *ngIf="!flag" routerLink="/auth/register" mat-button class="example-icon favorite-icon style-login registrate" style="margin: 10px 20px;" >
          Registrate
      </button>

      </mat-menu>
    </mat-toolbar>
  </mat-card>
  