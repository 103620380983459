<mat-nav-list fxLayout="column" fxLayoutAlign="space-between stretch" style="height: 100%;">
  <div class="logo">
    <img width="90%" src="/assets/images/logo.svg">
  </div>

  <div id="list-nav" *ngFor="let item of menu_user" fxLayout="column" fxLayoutAlign="center stretch">
    <a *ngIf="item.route_sub_menu=='/dashboard'" class="sidebarIcon" routerLink="{{item.route_sub_menu}}" routerLinkActive="item-active" [routerLinkActiveOptions]="{exact: true}">
      <span class="material-icons icon">{{item.icon_menu}}</span>
      <p>{{item.menu_name}}</p> 
    </a>
    <a *ngIf="item.route_sub_menu!='/dashboard'" class="sidebarIcon" routerLink="{{item.route_sub_menu}}" routerLinkActive="item-active" [routerLinkActiveOptions]="{match:[ 'item.route_sub_menu']}">
      <span class="material-icons icon">{{item.icon_menu}}</span>
      <p>{{item.menu_name}}</p> 
    </a>
  </div>
  
  <div fxFlex fxLayout="column" fxLayoutAlign="end center" style="margin-bottom: 10px;">
    <img width="45%" src="./assets/images/sogcial-min.svg">
  </div>
</mat-nav-list>
