import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class CourseWebsiteService {
  public baseurl: string;
  temp: string;
  headers = new Headers();


  constructor(private __http: HttpClient) {
    this.baseurl = environment.url_server;

  }

  get_levels():Observable<any>{
    this.temp = this.baseurl + 'levels';
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  get_courses(id):Observable<any>{
    this.temp = this.baseurl + 'getPlacesByCourse/'+id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  get_Listcourses(limit=null,id=null, text = null, category = null, country = null, level = null):Observable<any>{
    if(id==null&&limit!=null)
      this.temp = this.baseurl + `courses?limit=${limit}`;
    else
      if(id!=null&&limit!=null)
        this.temp = this.baseurl + `courses?limit=${limit}&lastId=${id}`;
      else
        this.temp = this.baseurl + `courses`;

    if(text && text !=""){
      this.temp = this.temp + `&text=${text}`;
    }
    if(level && level !=""){
      this.temp = this.temp + `&level=${level}`;
    }
    if(country && country !=""){
      this.temp = this.temp + `&country=${country}`;
    }
    if(category && category !=""){
      this.temp = this.temp + `&category=${category}`;
    }
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  get_courseDetail(id):Observable<any>{
    this.temp = this.baseurl + 'courses/'+id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  get_coursesCategories():Observable<any>{
    this.temp = this.baseurl + 'coursesCategories';
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }

}
