<div fxLayout="row" fxLayout.lt-md="column" ngxSplit="row">
  <div fxFlex="50%" ngxSplitArea>
    <div fxLayout="row" ngxSplit="row">
        <div fxFlex="10%" ngxSplitArea  class="back-address pointer">
          <mat-icon (click)="onBack()" style="font-size: 3em;">keyboard_backspace</mat-icon>

        </div>
        <div fxFlex="90%" ngxSplitArea class=" back-address">
          <button mat-button class="add-event" (click)="formCreditCard()" >{{"method_payment.button-credit" | translate}}</button>
          <a  (click)="formPaypal()" style="position: relative;top:0.6em"><img class="add-event-2" src="/assets/images/paypal.png"></a>
          <button mat-button class="add-event" (click)="formStripe()" >STRIPE</button>
        </div>

    </div>
    <div fxLayout="row" fxLayout.lt-md="column" ngxSplit="row" *ngIf="credit">
        <div fxFlex="100%" ngxSplitArea>
          <div fxLayout="row" ngxSplit="row">
            <mat-form-field class="example-full-width" fxFlex>
              <mat-label translate>login.email</mat-label>
              <input matInput placeholder="Ex. pat@example.com" value="" name="email" [(ngModel)]="email">
            </mat-form-field>
          </div>

          <div fxLayout="row" ngxSplit="row">
            <mat-form-field class="example-full-width" fxFlex>
              <mat-label>{{"method_payment.name-credit" | translate}}</mat-label>
              <input matInput placeholder="" name="cardName" [(ngModel)]="cardName" value="">
            </mat-form-field>
          </div>

          <div fxLayout="row" ngxSplit="row">
            <mat-form-field class="example-full-width">
              <mat-label>{{"method_payment.number-credit" | translate}}</mat-label>
              <input #card matInput placeholder="" maxlength="16" name="cardNumber" [(ngModel)]="cardNumber" value="">
              <mat-hint align="end">{{card.value?.length || 0}}/16</mat-hint>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px">
            <div fxFlex="50%" fxLayout="column" fxLayoutAlign="space-between stretch">
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                <mat-form-field class="example-full-width">
                  <mat-label>{{"method_payment.expired-credit" | translate}}</mat-label>
                  <input matInput min="2023-01"  type="month" name="expired" [(ngModel)]="expired" value="">
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="50%" fxLayout="column" fxLayoutAlign="space-evenly center">
              <mat-form-field class="example-full-width">
                <mat-label>{{"method_payment.secure-credit" | translate}}</mat-label>
                <input matInput #cvcS placeholder="CVC" maxlength="3" name="cvc" [(ngModel)]="cvc" value="">
                <mat-hint align="end">{{cvcS.value?.length || 0}}/3</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" ngxSplit="row">
            <mat-form-field class="example-full-width" *ngIf="!flag">
              <mat-label>Direccion de facturacion</mat-label>
              <mat-select name="address" [(ngModel)]="address">
                <mat-option *ngFor="let address of options" [value]="address.id">
                    {{address.address}}
                </mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="pari.invalid">{{getErrorMessage()}}</mat-error> -->
            </mat-form-field>
          </div>
          <div fxLayout="row" ngxSplit="row">
            <button mat-raised-button  (click)="saveCard()">{{"method_payment.save" | translate}}</button>
          </div>

        </div>




    </div>
    <div fxLayout="row" fxLayout.lt-md="column" ngxSplit="row" *ngIf="stripe">
      <div fxFlex="100%" ngxSplitArea>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px">
        </div>

        <h2>Create Token</h2>
      <!-- <ngx-stripe-card-group
        [formGroup]="stripeTest"
        [elementsOptions]="elementsOptions"
      >
        <input formControlName="name" />
        <input formControlName="amount" />
        <ngx-stripe-card-number [options]="cardOptions"></ngx-stripe-card-number>
        <ngx-stripe-card-expiry [options]="cardOptions"></ngx-stripe-card-expiry>
        <ngx-stripe-card-cvc [options]="cardOptions"></ngx-stripe-card-cvc>
      </ngx-stripe-card-group> -->
        <div fxLayout="row" ngxSplit="row">
          <button mat-raised-button  (click)="createToken()">{{"method_payment.save" | translate}}</button>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" ngxSplit="row" *ngIf="paypal">
        <div fxFlex="100%" ngxSplitArea>
          <div fxLayout="row" ngxSplit="row">
            <mat-form-field class="example-full-width">
              <mat-label translate>login.email`</mat-label>
              <input matInput #cvcS placeholder="tu@yo.es" type="email" required name="cvc" [(ngModel)]="emails" value="">
            </mat-form-field>
          </div>

          <div fxLayout="row" ngxSplit="row">
            <p><a (click)="save()" target="_blank"><img src="/assets/images/paypal-button.png"></a></p>
          </div>
        </div>
    </div>
  </div>
  <div fxFlex="50%" ngxSplitArea>

  </div>
</div>
